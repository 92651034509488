import React, {useState } from 'react';
import psygenieChromeImg from "../../../../img/psygenie_chrome.svg"
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import style from "../../../../css/Common.module.css";
import PlansList from "../plans/PlansList";
import Footer from "../../../../components/root/home/landing/Footer";

const SlackAppPsygenieSuccess = () => {
  const [paid] = useState(AuthenticationService.isPaidUser);
    return (
        <div className={style.integrationWindow}>
            <div className="container full-height d-flex justify-content-center align-items-center mb-5">
                <div className="text-center mb-5">
                    <img src={psygenieChromeImg} alt="Welcome to PsyGenie – Your Mindful Assistant! 🌟" width={400}/>
                    <div className="fs-2 text text-wrap">Welcome to PsyGenie – Your Mindful Assistant! 🌟</div>
                    <div className="fs-6 text-muted mt-2">Unlock powerful psychological insights and tools directly within your browser. <br/>
                        Whether it’s understanding behavior, boosting engagement, or enhancing well-being, PsyGenie has you covered. <br/>Start Exploring Now by simply adding our Chrome app to your browser! 🧠✨</div>
                    {!paid && (
                      <div className="fs-5 text text-wrap mt-3">Select your plan and start your journey today! 🚀</div>
                    )}

                   {paid && (
                       <>
                           <div className="fs-5 text text-wrap mt-5">🚀 Your Premium Plan is Active! 🚀</div>
                           <div className="fs-6 text-muted">Congratulations on upgrading to our premium plan! You now have complete access to all the exclusive features that will take your experience to the next level.</div>
                           <div className="fs-6 text-muted">Enjoy a suite of powerful tools, advanced insights, and enhanced capabilities — all designed to help you make the most of your journey with PsyGenie.</div>
                           <div className="fs-6 text-muted">Dive in and explore everything that’s now at your fingertips!</div>
                       </>
                   )}
                    <PlansList/>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default SlackAppPsygenieSuccess;

