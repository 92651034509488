import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import paymentSuccess from "../../../../img/payment-success.jpg";

const PlansSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { planName } = location.state || {};

    return (
        <div className="container mt-5 pt-5">
            <div className="row d-flex align-items-center">
                <div className="col-md-6">
                    <h2 className="text-success">You’re All Set! Payment Successful 🎉</h2>
                    <div className="fs-6">Thank you for your payment! Your transaction has been completed successfully. and you’re all set to enjoy the full benefits of PsyGenie.
                    </div>
                    <div>If you have any questions or need assistance, feel free to send us an email at [<code>info@psygenie.ai</code>].
                    </div>
                    <div className="mt-3">
                        <button className="btn btn-success" onClick={() => navigate("/")}>Return to Homepage</button>
                    </div>
                </div>
                <div className="col-md-6 text-center"><img src={paymentSuccess  } width={300}/></div>
            </div>
        </div>
)
    ;
};

export default PlansSuccess;