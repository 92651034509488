import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";

const PlanSelected = () => {
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    console.log("Fetching subscription details");

    axios
      .post(`/payments/subscription/checkout`, {
        username: AuthenticationService.getLoggedInUser(),
      })
      .then((response) => {
        setSubscriptionDetails(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching subscription details: ", error);
        setError("Failed to fetch subscription details. Please try again.");
        setLoading(false); // Turn off loading on error
      });
  }, []);

  const handlePayNow = () => {
    console.log("Sending to checkout: " + subscriptionDetails);
    navigate("/plans/checkout", {
      state: subscriptionDetails, // Passing subscription details to checkout
    });
  };

  const handleChangePlan = () => {
    navigate("/plans/change"); // Navigate to plan change page
  };

  // Conditional rendering based on loading and error states
  if (loading) {
    return <p>Loading subscription details...</p>;
  }

  if (error) {
    return <p>{error}</p>; // Display error message if fetching data fails
  }

  return (
    <div>
      <h2>Selected Plan</h2>
      {/* Ensure subscriptionDetails are not null before displaying plan info */}
      {subscriptionDetails ? (
        <div>
          <p>Plan Name: {subscriptionDetails.planName}</p>
          <p>
            Plan Price: {subscriptionDetails.planCurrency}{" "}
            {subscriptionDetails.planPrice}
          </p>
          <button onClick={handlePayNow}>Pay Now</button>
          <button onClick={handleChangePlan}>Change Plan</button>
        </div>
      ) : (
        <p>No subscription details available.</p>
      )}
    </div>
  );
};

export default PlanSelected;
