import React from 'react';
import ice from "../../../../img/landing/ice.png";
import personal from "../../../../img/landing/personal.png";
const PersonalIcebreakerSection = () => {
    return (
        <>
            <section id="contact" className="personal-info py-5 mt-5 bg-orange">
                <div className="personal-info-container container mt-5 mb-5 text-white">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="fs-1 fw-bold">Personal Info & Icebreakers</h2>
                            <p className="fs-3">"Always be prepared for meaningful interactions. PsyGenie provides quick
                                access to professional profiles, educational backgrounds, and hometown details, along
                                with curated icebreakers like trending topics, local news, and upcoming holidays.
                                Eliminate awkward silences and foster deeper connections with ease."</p>
                        </div>
                        <div className="col-md-3">
                            <img src={personal} alt="Personal Info"
                                 className="img-fluid border border-warning rounded-4"/>
                        </div>
                        <div className="col-md-3">
                            <img src={ice} alt="Icebreakers"
                                 className="img-fluid border border-warning rounded-4"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PersonalIcebreakerSection;