import React from 'react';
import behImage from "../../../../img/landing/behImage.png";
const BehaviorSection = () => {
    return (
        <>
            <section id="contact" className="instant-insight py-5 mt-5 bg-orange">
                <div className="instant-insight-container container mt-5 mb-5">
                    <div className="row">
                        
                        <div className="col-md-6 text-light">
                            <h2 className="fs-1 fw-bold">Behavior based Guidance</h2>
                            <p className="fs-3">"Unlock personalized behavior-based insights for any situation.
                                Understand what motivates or triggers individuals, and discover tailored do’s and don’ts
                                to leave a lasting impression. Receive real-time feedback and actionable advice for
                                scenarios like sales meetings with clients, performance appraisals with managers, and
                                more."</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={behImage} width="50%" alt="" className="img-fluid border border border-2 border-light rounded-3"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BehaviorSection;