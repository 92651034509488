import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import paymentFailed from "../../../../img/payment-failed.jpg";

const PlansFailed = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { message } = location.state || {};

    return (
        <div className="container mt-5 pt-5">
            <div className="row d-flex align-items-center">
                <div className="col-md-6">
                    <h2 className="text-danger">Oops! Your Payment Didn't Go Through</h2>
                    <div>{message}</div>
                    <div>It seems your payment didn’t go through, but if the amount has been deducted from your account,
                        rest assured—we’re here to help!
                    </div>
                    <div>Please reach out to us through email with your payment details, and we’ll resolve it as quickly
                        as possible.
                    </div>
                    <div>Email: <code>info@psygenie.ai</code> </div>
                    <div className="mt-3">
                        <button className="btn btn-warning" onClick={() => navigate("/")}>Try Again</button>
                    </div>
                </div>
                <div className="col-md-6 text-center"><img src={paymentFailed} width={300}/></div>
            </div>
        </div>
    );
};

export default PlansFailed;