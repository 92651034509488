import React, { useRef, useState } from "react";
import styles from "../../../../css/Header.module.css";
import Navbar from "./navbar/Navbar";
import { useLocation } from "react-router-dom";
import ProfileAlerts from "./profileAlert/ProfileAlerts";
import { useMenu } from "../../../../MenuProvider"; // Adjust path as needed
import Avatar from "./profileAlert/Avatar"; // Import the Avatar component
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import IconWithCount from "../../fragments/header/profileAlert/IconWithBadge";
import logostyle from "../../../../css/Logo.module.css";
import { hideHeader } from "../../../../api/common/PGCommon";
import DropdownWrapper from "../dropdown/DropdownWrapper";
import DropdownItem from "../dropdown/DropdownItem";
import DropdownSeparator from "../dropdown/DropdownSeparator";
import unsubImage from "../../../../img/unsub-ico.png";
import logoutIco from "../../../../img/logout-ico.png";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import Popup, { InfoPopup } from "../popup/PopUp";
import Checkbox from "../checkbox/Checkbox";

const Header = React.memo(() => {
  const { selectedMenu } = useMenu();
  const location = useLocation();

  const [showUnsubPopup, setShowUnsubPopup] = useState(false);
  const [noAccountDelete, setNoAccountDelete] = useState(true);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [unsubscribeStatus, setUnsubscribeStatus] = useState(null);
  const [accountDeletedStatus, setAccountDeletedStatus] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("Info");

  const userLogged = AuthenticationService.isUserLoggedIn();
  useRef(null);

  if (hideHeader(location)) {
    return null; // Hide header if on login page
  }

  const confirmUnsubscribe = () => {
    setNoAccountDelete(true);
    setShowUnsubPopup(true);
  };

  const handleNoAccountDeleteCheckbox = (e) => {
    console.log(e);
    setNoAccountDelete(e);
  };

  const handleUnsubscribe = async () => {
    console.log("Unsubscribe pressed with checkbox " + noAccountDelete);
    let unsubStatus = false;
    let deleteStatus = false;
    setUnsubscribing(true);
    try {
      const response = await axios.post("/payments/subscription/cancel", {
        username: AuthenticationService.getLoggedInUser(),
      });
      const { value } = response.data.data; // Extract the boolean result from response
      setUnsubscribeStatus(value);
      unsubStatus = value;

      if (unsubscribeStatus === true && noAccountDelete === false) {
        try {
          const response = await axios.delete(
            `/pg/user/${AuthenticationService.getLoggedInUser()}`,
          );
          if (response.status === 200) {
            setAccountDeletedStatus(true);
            deleteStatus = true;
          } else {
            setAccountDeletedStatus(false);
            deleteStatus = false;
          }
        } catch (error) {
          console.error("Failed to delete account:", error);
          setAccountDeletedStatus(false);
          deleteStatus = false;
        }
      }
    } catch (error) {
      console.error("Failed to unsubscribe:", error);
      setUnsubscribeStatus(false);
      unsubStatus = false;
      setAccountDeletedStatus(false);
      deleteStatus = false;
    } finally {
      setUnsubscribing(false);
      setShowUnsubPopup(false);

      let message = "";
      if (unsubStatus === true) {
        message = "Successfully unsubscribed.";
      } else if (unsubStatus === false) {
        message = "Failed to unsubscribe.";
      }

      if (noAccountDelete === false) {
        if (deleteStatus === true) {
          message = message + " Successfully deleted account.";
        } else if (deleteStatus === false) {
          message = message + " Failed to delete account.";
        }
      }

      console.log(message);
      setConfirmationMessage(message);
      setConfirmationPopup(true);
    }
  };

  const dropdownItems = (
    <>
      <DropdownItem
        label={"Unsubscribe"}
        image={unsubImage}
        onClick={confirmUnsubscribe}
      />
      <DropdownSeparator />
      <DropdownItem
        label={"Logout"}
        image={logoutIco}
        className={styles.logoutMenuItem}
        onClick={AuthenticationService.logout}
      />
    </>
  );

  const handleConfirmationClose = () => {
    setConfirmationPopup(false);
    setConfirmationMessage("");
    if (unsubscribeStatus === true || accountDeletedStatus === true) {
      AuthenticationService.logout();
    }
  };

  return (
    <>
      {userLogged && (
        <header id="HeaderLoggedIn" className="navbar sticky-top flex-md-nowrap p-0 landing_header_menu header text-black">
          <div className="container">
            <ProfileAlerts className="text-black"/>
            <div className={styles.headerWrapper}>
              <DropdownWrapper dropdownItems={dropdownItems}>
                <div className={styles.avatarAndText}>
                  <li>
                    <Avatar />
                  </li>
                  <div className={styles.textWrapper}>
                    <p>{AuthenticationService.getFullName()}</p>
                  </div>
                </div>
              </DropdownWrapper>
            </div>
          </div>
        </header>
      )}
      {!userLogged && (
        <header
          id="Header"
          className="navbar sticky-top flex-md-nowrap p-0 landing_header_menu header header_transparent"
          style={{ position: "fixed", width: "100%" }}
        >
          <div className="container">
            <ProfileAlerts />
            <Navbar />
          </div>
        </header>
      )}

      <Popup
        isOpen={showUnsubPopup}
        title="Unsubscribe"
        message="Are you sure you wish to unsubscribe?"
        type="warning"
        onClose={() => setShowUnsubPopup(false)}
        buttons={[
          {
            label: "Unsubscribe",
            handler: handleUnsubscribe,
            color: "grey",
            disabled: { unsubscribing },
          },
          {
            label: "Cancel",
            handler: () => setShowUnsubPopup(false),
            color: "#FF5F00FF",
            disabled: { unsubscribing },
          },
        ]}
      >
        <Checkbox
          label={"Do not delete my account"}
          defaultChecked={noAccountDelete}
          onChange={handleNoAccountDeleteCheckbox}
        />
      </Popup>
      <InfoPopup
        isOpen={confirmationPopup}
        message={confirmationMessage}
        onClose={handleConfirmationClose}
        closeButton={true}
      ></InfoPopup>
    </>
  );
});

export default Header;
