import React, { useEffect, useState } from "react";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import { useNavigate } from "react-router-dom";

const PlanActive = () => {
  const [planStatus, setPlanStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlanStatus = async () => {
      try {
        const user = AuthenticationService.getLoggedInUser();
        const response = await axios.post("/payments/subscription/details", {
          username: user,
        });
        setPlanStatus(response.data.data);
      } catch (err) {
        console.error("Error fetching plan status:", err);
        setError("Failed to fetch the current plan status. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlanStatus();
  }, []);

  const handleChangePlan = () => {
    navigate("/plans/change");
  };

  const handleCancelPlan = () => {
    navigate("/plans/cancel");
    console.log("Cancel plan clicked");
  };

  const handlePayNow = () => {
    axios
      .post(`/payments/subscription/checkout`, {
        user: AuthenticationService.getLoggedInUser(),
        cardChange: true,
      })
      .then((response) => {
        console.log("Fetched: ", response.data.data);
        navigate("/plans/checkout", {
          state: {
            // TODO : Complete this.
            planName: planStatus.planName,
            planPrice: planStatus.planPrice,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching subscription details: ", error);
        setError("Failed to fetch subscription details. Please try again.");
      });
  };

  if (loading) {
    return <p>Loading plan details...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <div>
      {planStatus ? (
        <>
          <h2>Active Plan</h2>
          <p>Plan Name: {planStatus.planName}</p>

          {/* Plan status handling */}
          {planStatus.status === "active" && (
            <>
              <p>Status: Active</p>
              <button onClick={handleChangePlan}>Change Plan</button>
              <button onClick={handleCancelPlan}>Cancel Plan</button>
            </>
          )}

          {planStatus.status === "pending" && (
            <>
              <p>Status: Payment Pending</p>
              <button onClick={handlePayNow}>Pay Now</button>
            </>
          )}

          {planStatus.status === "authenticated" && (
            <>
              <p>Status: Trial Period</p>
              <p>Trial Ending On: {planStatus.trialExpiry}</p>
              <button onClick={handleChangePlan}>Change Plan</button>
            </>
          )}

          {planStatus.status === "cancelled" && (<>
            <p>Status: Cancelled</p>
            <p>You subscription is cancelled and will expire at the end of subscription period: {new Date(planStatus.cycleEnd * 1000).toString()}</p>
          </>)}

          {/* Handle additional statuses if required */}
        </>
      ) : (
        <p>No active plan found.</p>
      )}
    </div>
  );
};

export default PlanActive;
