import HeroSectionOthers from "./common/HeroSectionOthers";
import contactusImg from "../../../img/contactus.png";
import React from "react";
import Footer from "./landing/Footer";

const description = "We’d love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to help.";

const ContactUs = () => {
    return (
        <div className="main-content-div">
            <HeroSectionOthers title="Get in Touch with PsyGenie" description={description}/>
            <section id="WhoWeAre" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h5>Email Us</h5>
                            <span className="fs-6">For general inquiries or support, please reach out to us at:</span>
                            <p><i className="fa-solid fa-envelope"></i> <b>Email</b>: [info@psygenie.ai]</p>

                            <h5>Our Address</h5>
                            <div>PsyGenie Software</div>
                            <div>H24, Forest County</div>
                            <div>EON Free Zone, Kharadi,</div>
                            <div className="mb-5">Pune, Maharashtra 411014</div>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.366074545606!2d73.94686587592804!3d18.557527668042848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3e7fd7f7939%3A0xe6f0175fc4b1c092!2sForest%20County%2C%20Gate%20No.1!5e0!3m2!1sen!2sin!4v1728208185089!5m2!1sen!2sin"
                                width="100%" height="350" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div className="col-md-6">
                            <img src={contactusImg} alt="" className="img-fluid rounded-4"/>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
};

export default ContactUs;