import React from "react";
import styles from "../../../css/AboutUs.module.css";
import umangimg from "../../../img/umang-team.png";
import ppred from "../../../img/aboutus/dev1.png";
import ppyellow from "../../../img/aboutus/dev2.png";
import pppink from "../../../img/aboutus/dev3.png";
import HeroSectionOthers from "./common/HeroSectionOthers";
import whoWeAre from "../../../img/aboutus/who-we-are.png";
import whatWeOffer from "../../../img/aboutus/what-we-offers.png";
import overVision from "../../../img/aboutus/our-vision.png";
import Footer from "./landing/Footer";
const description = "At PsyGenie, we empower you to connect better by blending psychology with technology. With seamless integration into Slack, Teams, WhatsApp, and LinkedIn, PsyGenie provides real-time insights into personality, emotions, and behavior. Enhance communication, tackle workplace situations, and build stronger, meaningful relationships effortlessly.";
const AboutUs = () => {
  return (
      <div className="main-content-div">
          <HeroSectionOthers title="About Us" description={description}/>
          <section id="WhoWeAre" className="py-1">
              <div className="container mt-2 mb-2">
                  <div className="row">
                      <div className="col-md-6 align-self-center">
                          <h2 className="fs-1 fw-bold text-orange">Who We Are</h2>
                          <p className="fs-5">
                              We are a team of technologists with 15+ years of experience who have personally faced the
                              challenges PsyGenie aims to solve. During the era of remote working and the isolation
                              brought by
                              COVID, we experienced the difficulties of building meaningful connections and navigating
                              workplace dynamics. This motivated us to create PsyGenie, a platform designed to address
                              these
                              challenges head-on. By integrating cutting-edge technology with psychological principles,
                              PsyGenie provides real-time suggestions and tailored insights, empowering individuals to
                              foster
                              better relationships and thrive in any professional setting.
                          </p>
                      </div>
                      <div className="col-md-6">
                          <img src={whoWeAre} alt="" className="img-fluid rounded-4"/>
                      </div>
                  </div>
              </div>
          </section>

          <section id="WhatWeOffer" className="py-1">
              <div className="container mt-2 mb-2">
                  <div className="row">
                      <div className="col-md-6">
                          <img src={whatWeOffer} alt="" className="img-fluid rounded-4"/>
                      </div>
                      <div className="col-md-6 align-self-center">
                          <h2 className="fs-1 fw-bold text-orange">What We Offer</h2>

                          <h5>Personalized Behavioral Insights:</h5>
                          <p>Unlock deep insights into the personalities and emotional triggers of yourself and others.
                              Understand what motivates or challenges individuals to foster better connections.</p>

                          <h5>Real-Time Assistance:</h5>
                          <p>Navigate workplace challenges effortlessly with PsyGenie's AI-driven suggestions. From
                              resolving conflicts to building rapport, get instant advice tailored to the situation.</p>

                          <h5>Tailored Icebreakers:</h5>
                          <p>Avoid awkward silences with ready-to-use conversation starters, from trending topics to
                              upcoming events, making every interaction engaging and meaningful.</p>

                          <h5>Support for Team Dynamics:</h5>
                          <p>Whether you're new to an organization or managing a team, PsyGenie helps you understand
                              interpersonal dynamics and provides actionable tips to build trust and harmony.</p>
                      </div>
                  </div>
              </div>
          </section>
          <section id="OurVision" className="py-1">
              <div className="container mt-2 mb-2">
                  <div className="row">
                      <div className="col-md-6 align-self-center">
                          <h2 className="fs-1 fw-bold text-orange">Our Vision</h2>
                          <p className="fs-5">We envision a world where people connect authentically and communicate
                              effectively,
                              leveraging psychological insights to navigate the complexities of human interactions. By
                              empowering
                              individuals with knowledge and tools, we aim to foster a more compassionate and
                              understanding
                              society.</p>
                      </div>
                      <div className="col-md-6">
                          <img src={overVision} alt="" className="img-fluid rounded-4"/>
                      </div>
                  </div>
              </div>
          </section>

          {/* <section id="OurTeam" className="">
          <div className="container mt-5 mb-5">
            <div className="row">
              <h2 className="fs-1 fw-bold text-orange">Meet Our Team</h2>
              <p>At PsyGenie, our team is the heart of what we do. We are a diverse group of professionals united by a shared passion for mental wellness and personal growth. Each member brings unique skills and experiences that contribute to our mission of empowering individuals through psychological</p>
              <div className="col-lg-3 p-5 text-center">
              <div>
                  <img
                      src={umangimg}
                      alt="Team Member 1"
                      className={styles.teamPhoto}
                      height={235}
                  />
                  <h3>Umang Parekh</h3>
                  <h6>Founder</h6>
                  <p>14 years of experience</p>
                </div>
              </div>
              <div className="col-lg-3 p-5 text-center">
                <div>
                  <img
                      src={ppred}
                      alt="Team Member 2"
                      className={styles.teamPhoto}
                      height={235}
                  />
                  <h3>Co-founder</h3>
                  <h6>Co-founder</h6>
                  <p>13 years of experience</p>
                </div>
              </div>
              <div className="col-lg-3 p-5 text-center">
                <div>
                  <img
                      src={ppyellow}
                      alt="Team Member 3"
                      className={styles.teamPhoto}
                      height={235}
                  />
                  <h3>Co-founder</h3>
                  <h6>Co-founder</h6>
                  <p>4 years of experience</p>
                </div>
              </div>
              <div className="col-lg-3 p-5 text-center">
                <div>
                  <img
                      src={pppink}
                      alt="Team Member 4"
                      className={styles.teamPhoto}
                      height={235}
                  />
                  <h3>Co-founder</h3>
                  <h6>Co-founder</h6>
                  <p>4 years of experience</p>
                </div>
              </div>

            </div>
          </div>
        </section> */}
          <section id="OurTeam" className="py-1">
              <div className="container mt-2 mb-2">
                  <div className="row">
                      <h2 className="fs-1 fw-bold text-orange">Meet Our Founder</h2>
                      <div className="col-md-3 p-5 text-center">
                          <div>
                              <img
                                  src={umangimg}
                                  alt="Team Member 1"
                                  className={styles.teamPhoto}
                                  height={235}
                              />
                              <h3>Umang Parekh</h3>
                              <h6>Founder</h6>
                              <p>14 years of experience</p>
                          </div>
                      </div>

                      <div className="col-md-9 p-5 text-center">
                          <p className="fs-5 text-start mt-4">Umang Parekh is the driving force behind PsyGenie, blending technology with psychological principles to create a practical and impactful tool. With a background in technology and a deep understanding of workplace challenges, Umang saw the need for a solution that fosters better connections and smoother interactions. His dedication to simplifying human connections and addressing everyday challenges has shaped PsyGenie into a platform designed to empower individuals with practical insights and strategies for meaningful relationships and improved communication.</p>
                      </div>

                  </div>
              </div>
          </section>

          <section id="JoinUs" className="py-1">
              <div className="container mt-2">
                  <div className="row">
                      <div className="col-md-12 align-self-center">
                          <h2 className="fs-1 fw-bold text-orange">Join Us on This Journey</h2>
                          <p className="fs-5">Whether you're looking to improve your relationships, understand yourself
                              better, or
                              develop new skills, PsyGenie is here to support you every step of the way. Together, let’s
                              unlock the
                              potential of the human mind!</p>
                      </div>
                  </div>
              </div>
          </section>
          <Footer/>
      </div>
  );
};

export default AboutUs;
