import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";

const PlanCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subscriptionDetails = location.state || {}; // Access entire state object

  useEffect(() => {
    console.log("Subscription Details ", subscriptionDetails); // TODO: Remove this in production

    const {
      razorpayKey,
      subscriptionId,
      planName,
      customerName,
      customerEmail,
      customerPhone,
      cardChange,
    } = subscriptionDetails;

    if (!razorpayKey || !subscriptionId) {
      console.error("Missing required payment details.");
      navigate("/plans/failed", {
        state: {
          message: "Error fetching subscription details. Please try again.",
        },
      });
      return;
    }

    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
      });
    };

    const initRazorpay = async () => {
      const scriptLoaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js",
      );

      if (!scriptLoaded) {
        alert("Razorpay SDK failed to load. Please check your connection.");
        navigate("/plans/failed", {
          state: {
            message: "Failed to load payment gateway. Please try again.",
          },
        });
        return;
      }

      const options = {
        key: razorpayKey, // Razorpay API Key
        subscription_id: subscriptionId, // Subscription ID
        name: "PsyGenie",
        description: planName,
        handler: function (response) {
          axios
            .post(
              `/payments/subscription/verify`,
              {
                ...response,
                username: AuthenticationService.getLoggedInUser(),
              },
              {
                headers: { "Content-Type": "application/json" },
              },
            )
            .then((res) => {
              const { value } = res.data.data;
              if (value) {
                navigate("/plans/success", { state: { plan: planName } });
              } else {
                navigate("/plans/failed", {
                  state: { message: "Error while verifying subscription." },
                });
              }
            })
            .catch((err) => {
              console.error("Error verifying payment: ", err); // TODO: Remove this in production
              navigate("/plans/failed", {
                state: { message: "Error while verifying subscription." },
              });
            });
        },
        theme: {
          color: "#e35700", // Branding color
        },
      };

      // if (customerName || customerEmail || customerPhone) {
      //   options.prefill = {
      //     name: customerName || "",
      //     email: customerEmail || "",
      //     contact: customerPhone || "",
      //   };
      // }

      if (cardChange === true || cardChange === "true") {
        options.subscription_card_change = true;
      }

      const rzp = new window.Razorpay(options);
      rzp.open();
    };

    initRazorpay(); // Initialize Razorpay checkout
  }, [subscriptionDetails, navigate]);

  return (
    <div className="shadow-lg p-3 mb-5 bg-white rounded">
      <h2>PsyGenie Checkout</h2>
      <p>You are purchasing: {subscriptionDetails.planName}</p>
      <button onClick={() => navigate("/")}>Return to Homepage</button>
    </div>
  );
};

export default PlanCheckout;
