.plans_list_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.plans_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 80%;
}

.plan_card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.plan_card h3 {
  margin-bottom: 10px;
}

.plan_card p {
  margin: 5px 0;
}

.subscribe_btn {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subscribe_btn:hover {
  background-color: #0056b3;
}