import React from "react";
import styles from "../../../../css/DropdownItem.module.css";
import clsx from "clsx";

const DropdownItem = ({ label, onClick, image, className }) => {
  return (
    <div className={clsx(styles.dropdownItem, className)} onClick={onClick}>
      {image && <img src={image} alt="icon" className={styles.image} />}{" "}
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default DropdownItem;
