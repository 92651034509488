import {fetchAuthSession, getCurrentUser, signInWithRedirect, signOut} from 'aws-amplify/auth';
import {Hub} from "aws-amplify/utils";
import axios from "axios";
import Cookies from 'js-cookie';
import awsConfig from '../../../src/api/config/awsConfig';
import customAxiosConfig from "../customAxiosConfig/CustomAxiosConfig";

Hub.listen("auth", async ({payload}) => {
  switch (payload.event) {
    case "signInWithRedirect":
      document.getElementById("PGLoader").classList.remove("hide");
      document.getElementById("PGLoader").classList.add("show");
      const redirectURL = localStorage.getItem("redirectURL");
      const user = await getCurrentUser();
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      sessionStorage.clear();
      sessionStorage.setItem("authenticatedUser", user.username);
      sessionStorage.setItem("role", "user");
      // Set jwt token for backend validation
      const token = `Bearer ${accessToken}`;
      Cookies.set('token', token, { expires: 7, secure: true, sameSite: 'None' });
      Cookies.set('app_server_url', `${process.env.REACT_APP_BACKEND_BASE_URL}`);
      // Check user is registered or not with psygenie
      authenticateUser(user.username);
      if (redirectURL) {
        localStorage.removeItem("redirectURL");
        window.location.href = redirectURL;
      } else {
        console.log(JSON.stringify(user));
        window.location.href = "/slack/app/psygenie/success";
      }
      break;
    case "signInWithRedirect_failure":
      // handle sign in failure
      break;
    case "customOAuthState":
      const state = payload.data; // this will be customState provided on signInWithRedirect function
      console.log(state);
      break;
  }
});

const authenticateUser = (username) => {
  try {
    return axios
        .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pg/authenticate/cognito`, null, {
          params: {
            "username": username
          }
        })
        .then((response) => {
          if(response.status === 200) {
            sessionStorage.setItem("authenticatedUser", username);
            sessionStorage.setItem("fullname", response.data.fullname);
            sessionStorage.setItem("role", "user");
            sessionStorage.setItem("paid", response.data.paid)
            sessionStorage.setItem("slack_client_id", response.data.slack_client_id);
            sessionStorage.setItem("slack_scopes", response.data.slack_scopes);
            sessionStorage.setItem("slack_app_id", response.data.slack_app_id);
            Cookies.set('fullname', response.data.fullname, { expires: 7, secure: true, sameSite: 'None' });
          }
        })
        .catch((err) => {
          let error = "";

          if (err.response) {
            error += err.response;
          }
          return error;
        });
  } catch (err) {
    let error = "";
    if (err.response) {
      error += err.response;
    }
    return error;
  }
}

// Signout and clear session and local storage
const  signOutHandler  = async () => {
  try {
    signOut().then(r =>
        clearCache()
    );
  } catch (err) {
    console.log(err)
  }
};

const clearCache = async () => {
  localStorage.clear();
  Cookies.remove('token');
  sessionStorage.clear();
  window.location.href = `https://${awsConfig.Auth.Cognito.loginWith.oauth.domain}/logout?client_id=${awsConfig.Auth.Cognito.userPoolClientId}&response_type=code&logout_uri=${awsConfig.Auth.Cognito.loginWith.oauth.redirectUri}&redirect_uri=${awsConfig.Auth.Cognito.loginWith.oauth.redirectUri}`;
}

const signInHandler = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isChromeExtensionLogin = urlParams.get("source") === "chrome_extension";

  if (isChromeExtensionLogin) {
          localStorage.setItem("redirectURL", "chrome-extension://pmoeokecbghjcfolplfldnmidpbjhfdp/popup.html");
  }
  await signInWithRedirect();
}

class AuthenticationService {
  awsCognitoEnabled = true;

  registerSuccessfulLoginUser(username,fullname, paid, slackClienId, slackScope, slackAppID) {
    sessionStorage.setItem("authenticatedUser", username);
    sessionStorage.setItem("fullname", fullname);
    sessionStorage.setItem("role", "user");
    sessionStorage.setItem("paid", paid)
    sessionStorage.setItem("slack_client_id", slackClienId);
    sessionStorage.setItem("slack_scopes", slackScope);
    sessionStorage.setItem("slack_app_id", slackAppID);
    console.log("Successful login");
  }

  logout() {
    signOutHandler();
    localStorage.clear();
    Cookies.remove('token');
    sessionStorage.clear();
    window.location.reload(true);
  }

  signIn() {
    signInHandler();
  }

  isUserLoggedIn() {
    let role = sessionStorage.getItem("role");
    return role === "user";
  }

  isBusinessLoggedIn() {
    let role = sessionStorage.getItem("role");
    return role === "business";
  }

  isPaidUser() {
    authenticateUser(sessionStorage.getItem("authenticatedUser"));
    let paid = sessionStorage.getItem("paid");
    return paid === "true";
  }

  getFullName() {
    return sessionStorage.getItem("fullname");
  }

  getLoggedInUser() {
    let username = sessionStorage.getItem("authenticatedUser");
    if (username == null) {
      return "";
    } else {
      return username;
    }
  }

  getSlackAuthorizeURL() {
    const slackURL = "https://slack.com/oauth/v2/authorize?client_id=" + sessionStorage.getItem("slack_client_id") + "&scope=" + sessionStorage.getItem("slack_scopes");
    return slackURL;
  }

  getSlackOpenURL() {
    return "https://slack.com/app_redirect?app=" + sessionStorage.getItem("slack_app_id");
  }

  setUpToken(jwtToken) {
   Cookies.set('token', jwtToken, { expires: 7, secure: true, sameSite: 'None' });
  }


}

export default new AuthenticationService();
