.integrationWindow {
    padding-top: 10px;
    background: whitesmoke;
    padding-top:25px;
}

.integrationWindowContent {
    width: 500px;
    background: white;
    padding: 20px;
    border-radius: 2%;
    margin-top: 15px;
}