import React, { useState } from "react";
import logostyle from "../../../../../css/Logo.module.css";
import logo from "../../../../../img/logo.svg";
import AuthenticationService from "../../../../../api/authentication/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconWithCount from "../profileAlert/IconWithBadge";
import { faBell, faMailBulk, faTasks } from "@fortawesome/free-solid-svg-icons";

const ProfileAlerts = () => {
  const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
  const isBusinessLoggedIn = AuthenticationService.isBusinessLoggedIn();

  return (
    <>
      {!isUserLoggedIn && (
        <section className={logostyle.landing_logo_container}>
          <Navbar variant="light" expand="lg">
            <Navbar.Brand href="/">
              <img src={logo} alt="PsyGenie" width="40px" />
              <span>&nbsp;PsyGenie</span>
            </Navbar.Brand>
          </Navbar>
        </section>
      )}

      {isUserLoggedIn && (
          <Navbar variant="dark" expand="lg">
            <Navbar.Brand href="/">
              <img src={logo} alt="PsyGenie" width="40px"/>
              <span className="text-black">&nbsp;PsyGenie</span>
            </Navbar.Brand>
          </Navbar>
      )}
    </>
  );
};

export default ProfileAlerts;
