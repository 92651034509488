// src/components/TestimonialsSection.jsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ManishImg from "../../../../img/manish.jpg"
import SamuelImg from "../../../../img/Samuel.jpg"
import MukeshImg from "../../../../img/mukesh.jpg"
import SteveImg from "../../../../img/steve.jpg"

const TestimonialsSection = () => {
    return (
        <section id="testimonials" className="py-5 bg-light testimonials">
            <Container>
                <h2 className="text-center mb-4">What Our PsyGenie Users Say</h2>
                <Row>
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Header><img src={ManishImg} width={150} className="testimonial-imaeg"/></Card.Header>
                            <Card.Body>
                                <Card.Text>"PsyGenie is the reason I now call my colleagues my friends!"</Card.Text>
                            </Card.Body>
                            <Card.Footer className="">
                                <div className="fw-semibold fs-14">Manish Kumar</div>
                                <div className="fs-12 text-muted">Engineering Manager</div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md={3}>
                    <Card className="text-center">
                            <Card.Header><img src={SamuelImg} width={150} className="testimonial-imaeg"/></Card.Header>
                            <Card.Body>
                                <Card.Text>"With PsyGenie Bot, I effortlessly handle situations and communications that used to be stressful. I never imagined simple psychological tricks could be so effective!"</Card.Text>
                            </Card.Body>
                            <Card.Footer className="">
                                <div className="fw-semibold fs-14">Samuel Prashker</div>
                                <div className="fs-12 text-muted">Senior Cloud Architect</div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Header><img src={MukeshImg} width={150} className="testimonial-imaeg"/></Card.Header>
                            <Card.Body>
                                <Card.Text>"As someone new to the Org, PsyGenie became my guide. It helped me connect with colleagues, navigate conversations and feel at home in no time!"</Card.Text>
                            </Card.Body>
                            <Card.Footer className="">
                                <div className="fw-semibold fs-14">Mukesh kumar</div>
                                <div className="fs-12 text-muted">Software Engineer</div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="text-center">
                            <Card.Header><img src={SteveImg} width={150} className="testimonial-imaeg"/></Card.Header>
                            <Card.Body>
                                <Card.Text>"Managing a team and struggling to build bonds? PsyGenie is for you."</Card.Text>
                            </Card.Body>
                            <Card.Footer className="">
                                <div className="fw-semibold fs-14">Steve Holmes</div>
                                <div className="fs-12 text-muted">Product Leader</div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TestimonialsSection;