.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 990;
}

.popup {
  position: absolute;
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: auto;
  min-width: 15%;
  max-width: 30%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  z-index: 990;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titlebar {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
}

.title {
  margin: 0;
  font-size: 20px;
}

.close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.message {
  margin: 8px 0;
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.button {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.cancel {
  background: grey;
  color: white;
}

.childrenContainer {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
}
