import React from "react";
import Footer from "./landing/Footer";
import HeroSectionOthers from "./common/HeroSectionOthers";
import encryptionImg from "../../../img/security/encription.png";
import complianceImg from "../../../img/security/compliance.png";
import minimumDataImg from "../../../img/security/minimum-data.png";
import secureAccessImg from "../../../img/security/secure-access.png";
import systemAuditImg from "../../../img/security/security-audit.png";
import userAuthenticationImg from "../../../img/security/user-authentication.png";
import phishingImg from "../../../img/security/phishing.png"; // Make sure this path is correct


const description =
    "At PsyGenie, we prioritize the security and privacy of our users' information. We implement a variety of measures to protect your data and ensure that your experience with us is safe and secure.";

const Security = () => {
    return (
        <div className="main-content-div">
            <HeroSectionOthers title="Security" description={description}/>
            <section id="Security" className="py-5">
                <div className="container">
                    <div className="row">
                        <h2 className="fs-3 text-orange mb-5 text-center">
                            Our Commitment to Security
                        </h2>

                        <div className="col-md-6 d-flex align-items-center mb-4">
                            <div className="me-3">
                                <img
                                    src={systemAuditImg}
                                    alt="Regular Security Audits"
                                    width={70}
                                    className="img-fluid"
                                />
                            </div>
                            <div>
                                <h3 className="fs-5 text-orange">Regular Security Audits</h3>
                                <p className="fs-6">
                                    We perform regular audits to proactively identify and resolve
                                    vulnerabilities, ensuring platform security.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 d-flex align-items-center mb-4">
                            <div className="me-3">
                                <img
                                    src={userAuthenticationImg}
                                    alt="Strong Authentication Methods"
                                    width={70}
                                    className="img-fluid"
                                />
                            </div>
                            <div>
                                <h3 className="fs-5 text-orange">Strong Authentication Methods</h3>
                                <p className="fs-6">
                                    We implement robust authentication methods, including optional
                                    Two-Factor Authentication (2FA), to secure user accounts.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 d-flex align-items-center mb-4">
                            <div className="me-3">
                                <img
                                    src={minimumDataImg}
                                    alt="Data Minimization"
                                    width={70}
                                    className="img-fluid"
                                />
                            </div>
                            <div>
                                <h3 className="fs-5 text-orange">Data Minimization</h3>
                                <p className="fs-6">
                                    We collect only essential information to provide our services,
                                    reducing data exposure risks.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 d-flex align-items-center mb-4">
                            <div className="me-3">
                                <img
                                    src={complianceImg}
                                    alt="Regulatory Compliance"
                                    width={70}
                                    className="img-fluid"
                                />
                            </div>
                            <div>
                                <h3 className="fs-5 text-orange">Regulatory Compliance</h3>
                                <p className="fs-6">
                                    We adhere to global standards such as GDPR and CCPA, ensuring
                                    transparency and responsible data handling.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 d-flex align-items-center mb-4">
                            <div className="me-3">
                                <img
                                    src={encryptionImg}
                                    alt="Data Encryption"
                                    width={70}
                                    className="img-fluid"
                                />
                            </div>
                            <div>
                                <h3 className="fs-5 text-orange">Data Encryption</h3>
                                <p className="fs-6">
                                    We secure your data with AES encryption for data at rest and
                                    HTTPS for data in flight, ensuring confidentiality and
                                    security.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Security" className="py-5">
                <div className="container">
                    <div className="row mb-4">
                        <h4 className="fs-3 text-orange">What You Can Do</h4>
                        <p className="fs-6">
                            Here’s how you can help ensure your data remains secure:
                        </p>
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-md-2 text-center">
                            <img
                                src={secureAccessImg}
                                alt="Secure Access"
                                width={70}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-10">
                            <h2 className="fs-5 text-orange">Strong Passwords</h2>
                            <p className="fs-6">
                                Use strong, unique passwords for your PsyGenie account and change them regularly.
                            </p>
                        </div>
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-md-2 text-center">
                            <img
                                src={encryptionImg}
                                alt="Enable 2FA"
                                width={70}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-10">
                            <h2 className="fs-5 text-orange">Enable 2FA</h2>
                            <p className="fs-6">
                                Enhance your account security by enabling Two-Factor Authentication (2FA).
                                Protect your information with an extra layer of verification at login!
                            </p>
                        </div>
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-md-2 text-center">
                            <img
                                src={phishingImg}
                                alt="Be Cautious"
                                width={70}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-10">
                            <h2 className="fs-5 text-orange">Be Cautious</h2>
                            <p className="fs-6">
                                Be vigilant about phishing attempts and do not share your account information with
                                anyone.
                                These attacks often come in the form of emails, messages, or fake websites that appear
                                legitimate.
                            </p>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h4 className="fs-3 text-orange">Reporting Security Concerns</h4>
                            <p className="fs-6">
                                If you suspect any security issues or have concerns about your account, please contact
                                us
                                immediately at <strong>info@psygenie.ai</strong>. We take all reports seriously and will
                                investigate promptly.
                            </p>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h4 className="fs-3 text-orange">Changes to This Security Policy</h4>
                            <p className="fs-6">
                                We may update our security measures from time to time to address new challenges and
                                enhance our protections.
                                We encourage you to review this page periodically for any changes.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
};
export default Security;