import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import AuthenticationService from "../../../../../../api/authentication/AuthenticationService";
import { NavLink } from "react-router-dom";
import styles from "../../../../../../css/Navbar.module.css";
import {Button} from "react-bootstrap";
import CognitoLoginService from "../../../../../../api/login/CognitoLoginService";
import SlackRedirectService from "../../../../../../api/slack/SlackRedirectService";
import chromeIcon from "../../../../../../img/google_chrome_icon.png";


const NavLinks = () => {
  const userLogged = AuthenticationService.isUserLoggedIn();
  const businessLogged = AuthenticationService.isBusinessLoggedIn();
  const location = useLocation();

  const scrollToSection = (section) => {
    const element = document.querySelector(section)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const addToChrome = () => {
    window.open('https://chromewebstore.google.com/category/extensions', '_blank');
  }

  return (
    <>
      {userLogged && (
          <ul className={styles.nav_links}>
            <li className={styles.nav_link}>
            </li>
            <li className={styles.nav_link}>
            </li>
          </ul>
      )}

      {!userLogged && !businessLogged && (
          <ul className={styles.nav_links}>
            <li className={styles.navbar}>
              <NavLink to="/#Hero" onClick={() => scrollToSection('#Hero')}>Home</NavLink>
            </li>
            <li className={styles.navbar}>
              <NavLink to="/#Feature" onClick={() => scrollToSection('#Feature')}>Features</NavLink>
            </li>
            {/*<li className={styles.navbar}>
              <NavLink to="#Services" onClick={() => scrollToSection('#Services')}>Services</NavLink>
            </li>*/}
            {/*<li className={styles.navbar}>*/}
            {/*  <NavLink to="/#Dashboard" onClick={() => scrollToSection('#Dashboard')}>Dashboard</NavLink>*/}
            {/*</li>*/}
            <li className={styles.navbar}>
              <NavLink to="/#Plans" onClick={() => scrollToSection("#Plans")}>Pricing</NavLink>
            </li>
            {/*<li className={styles.navbar}>*/}
            {/*  <NavLink to="/#Blogs" onClick={() => scrollToSection("#Blogs")}>Blog</NavLink>*/}
            {/*</li>*/}
            <li className={styles.navbar}>
                <NavLink to="/aboutus" onClick={() => scrollToSection("#root")} >About</NavLink>
            </li>
            <li className={styles.navbar}>
                <NavLink onClick={() => scrollToSection("#root")} to="/security">Security</NavLink>
            </li>

            {/*{location.pathname !== "/login" && (*/}
            {/*    <li className={styles.navbar}>*/}
            {/*      /!*<Button variant="outline-primary" size="md" onClick={CognitoLoginService}>Login</Button>*!/*/}
            {/*      <NavLink className="btn btn-light rounded-4" to="/login">Login</NavLink>*/}
            {/*    </li>*/}
            {/*)}*/}
            <li className={styles.navbar}>
              <button className="btn-add-to-chrome rounded-4" size="md" onClick={addToChrome}>
                <img src={chromeIcon} width={20}/> Add to Chrome</button>
            </li>
          </ul>
      )}
    </>
  );
};

export default NavLinks;
