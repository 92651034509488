import React, { useEffect, useState } from "react";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import styles from "../../../../css/PlansList.module.css";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import * as PropTypes from "prop-types";

function PlanCard(props) {
  return (
    <div className={styles.plan_card}>
      <h3>{props.plan.name}</h3>
      <p>{props.plan.description}</p>
      <p>
        <strong>Price:</strong> {props.plan.currency} {props.plan.price} (
        {props.plan.period})
      </p>
    </div>
  );
}

PlanCard.propTypes = { plan: PropTypes.any };
const PlansView = () => {
  const [plans, setPlans] = useState([]);
  const [user, setUser] = useState(AuthenticationService.getLoggedInUser);

  useEffect(() => {
    axios
      .get(`/payments/subscription/plans/visible`)
      .then((response) => {
        const { data, success, error } = response.data;
        if (success) {
          setPlans(response.data.data);
        } else {
          console.error(`Error: ${error}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.plans_list_container}>
      <h2>PsyGenie Plans</h2>
      <div className={styles.plans_grid}>
        {plans.map((plan) => (
          <PlanCard key={plan.code} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default PlansView;
