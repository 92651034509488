import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsExports from '../src/api/config/awsConfig';
import Header from "./components/root/fragments/header/Header";
import AuthenticationService from "./api/authentication/AuthenticationService";
import Home from "./components/root/home/Home";
import SignUp from "./components/root/users/signUp/SignUp";
import Login from "./components/root/users/login/Login";
import ProtectedRoutesGuest from "./components/protectedRoutes/ProtectedRoutesGuest";
import ProtectedRoutesUser from "./components/protectedRoutes/ProtectedRoutesUser";
import PasswordChange from "./components/root/users/login/forgottenPassword/PasswordChange";
import SetUpNewPassword from "./components/root/users/login/forgottenPassword/SetUpNewPassword";
import ProfilePage from "./components/root/users/myProfile/MyProfileInfo";
import Sidebar from "./components/root/fragments/header/Sidebar";
import MyCircle from "./components/root/users/circle/MyCircle";
import UserView from "./components/root/users/myProfile/UserViewInfo";
import Action from "./components/root/users/myProfile/Action";
import { MenuProvider } from "./MenuProvider";
import AboutUs from "./components/root/home/AboutUs";
import Settings from "./components/root/users/myProfile/Settings";
import Training from "./components/root/users/myProfile/Training";
import Plans from "./components/root/users/plans/Plans";
import Footer from "./components/root/home/landing/Footer";

import friends from "./Friend";

import SlackAuthenticateService from "./api/slack/SlackAuthenticateService";
import SlackAppPsygenieSuccess from "./components/root/users/connection/SlackAppPsygenieSuccess";
import SlackAppPsygenieAllow from "./components/root/users/connection/SlackAppPsyGenieAllow";
import Loader from "./components/root/fragments/Loader/Loader";
import PlansList from "./components/root/users/plans/PlansList";
import PlansView from "./components/root/users/plans/PlansView";
import PlanCheckout from "./components/root/users/plans/PlanCheckout";
import PlansFailed from "./components/root/users/plans/PlanFailed";
import PlansSuccess from "./components/root/users/plans/PlanSuccess";
import PlanSelected from "./components/root/users/plans/PlanSelected";
import PlanActive from "./components/root/users/plans/PlanActive";
import Privacy from "./components/root/home/Privacy";
import Terms from "./components/root/home/Terms";
import ContactUs from "./components/root/home/ContactUs";
import Security from "./components/root/home/Security";
import Logout from "./components/root/users/login/Logout";
import ScrollToHash from "./components/root/fragments/header/navbar/navLinks/ScrollToHash";

if (AuthenticationService.awsCognitoEnabled) {
  Amplify.configure(awsExports);
}
const people = friends;

window.addEventListener('scroll', function() {
  const myDiv = document.getElementById('Header');
  if (myDiv != null) {
    if (window.scrollY > 65) {
      myDiv.classList.remove("header_transparent");
      myDiv.classList.add("header_white");
    } else {
      myDiv.classList.remove("header_white");
      myDiv.classList.add("header_transparent");

    }
  }
});

function App(user) {
  return (
    <MenuProvider>
      <Router>
        <ScrollToHash />
        <div className="App">
          <Header />
          {AuthenticationService.isUserLoggedIn() && <Sidebar />}
          <Routes>
            <Route element={<ProtectedRoutesGuest />}>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/change-password" element={<PasswordChange />} />
              <Route path="/password/:id" element={<SetUpNewPassword />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/security" element={<Security/>} />

            </Route>
            <Route element={<ProtectedRoutesUser />}>
              /*<Route path="/profile" element={<ProfilePage />} />
              <Route path="/circle" element={<MyCircle />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/user/:id" element={<UserView />} />
              <Route path="/action" element={<Action />} />
              <Route path="/setting" element={<Settings />} />
              <Route path="/training" element={<Training />} />*/
              <Route path="/slack/authenticate" element={<SlackAuthenticateService/>} />

              <Route path="/slack/app/psygenie/allow" element={<SlackAppPsygenieAllow/>} />
              <Route path="*" element={<SlackAppPsygenieSuccess/>} />
              <Route path="/profile" element={<SlackAppPsygenieSuccess/>} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/plans/list" element={<PlansList />} />
              <Route path="/plans/view" element={<PlansView />} />
              <Route path="/plans/checkout" element={<PlanCheckout />} />
              <Route path="/plans/failed" element={<PlansFailed />} />
              <Route path="/plans/success" element={<PlansSuccess />} />
              <Route path="/plans/selected" element={<PlanSelected />} />
              <Route path="/plans/active" element={<PlanActive />} />
              <Route path="/slack/app/psygenie/success" element={<SlackAppPsygenieSuccess/>} />
            </Route>
          </Routes>
            <div id="PGLoader" className="loader hide"><Loader /></div>
        </div>
      </Router>
    </MenuProvider>
  );
}
export default App;
