.checkboxComponent {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%; /* Circular border */
  border: 2px solid; /* Circular border */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}

.labelText {
  font-size: 16px;
}
