import { signInWithRedirect} from "aws-amplify/auth";
import {Amplify} from "@aws-amplify/core";
import AuthenticationService from "../authentication/AuthenticationService";

const CognitoLoginService = async () => {
    try {
        console.log(JSON.stringify(Amplify.getConfig().Auth));
        AuthenticationService.signIn();
    } catch (error) {
        console.log('error signing in', error);
    }
};

export default CognitoLoginService;