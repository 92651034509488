import axios from "axios";
import Cookies from 'js-cookie';


// axios instance for making requests
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  config.headers["Authorization"] = Cookies.get('token');
  return config;
});

export default axiosInstance;
