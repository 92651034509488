import HeroSectionOthers from "./common/HeroSectionOthers";
import React from "react";
import Footer from "./landing/Footer";

const description = "This Privacy Policy explains how Psygenie Softwares collects, uses, and protects your information when you use our Chrome plugin, Psygenie. By using our plugin, you agree to the terms of this Privacy Policy.";

const Privacy = () => {
    return (
        <div className="main-content-div">
            <HeroSectionOthers title="Privacy Policy for PsyGenie" description={description}/>
            <section id="PrivacyPolicy" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 align-self-center">
                            <div className="tab-content translations-content-item en visible" id="en">
                                <p><strong>Effective Date:</strong> January 17, 2025</p>
                                <h2 className="text-orange">Privacy Policy for Psygenie Softwares</h2>
                                <p>Psygenie Softwares (“we,” “our,” or “us”) values your privacy. This Privacy Policy
                                    explains how we collect, use, and protect your information when you use our Chrome
                                    plugin, Psygenie, designed to help users connect better by analyzing resumes and
                                    day-to-day interactions. By using our plugin, you agree to the terms of this Privacy
                                    Policy.</p>

                                <h4 className="text-orange">1. Information We Collect</h4>
                                <h5 ><strong>a. Personal Data</strong></h5>
                                <ul>
                                    <li>Names, email addresses, educational background, professional experience, and
                                        other details in resumes.
                                    </li>
                                    <li>Demographic information such as age, gender, and nationality.</li>
                                </ul>
                                <h5><strong>b. Behavioral Data</strong></h5>
                                <p>We collect anonymized behavioral data, such as:</p>
                                <ul>
                                    <li>Usage patterns, including the frequency of use and types of predictions
                                        requested.
                                    </li>
                                </ul>
                                <h5><strong>c. Cookies</strong></h5>
                                <p>We use cookies to improve your experience. You will be prompted to accept or decline
                                    cookies when you first use the Plugin. You can also update your cookie preferences
                                    at any time through your browser settings.</p>

                                <h4 className="text-orange">2. How We Use Your Information</h4>
                                <ul>
                                    <li>To provide personalized predictions and recommendations based on user-provided
                                        data.
                                    </li>
                                    <li>To analyze anonymized behavioral data for product improvement.</li>
                                    <li>To enhance the user experience.</li>
                                </ul>
                                <p><strong>Note:</strong> The data is not used for training machine learning models or
                                    shared for marketing purposes.</p>

                                <h4 className="text-orange">3. Data Storage and Retention</h4>
                                <ul>
                                    <li><strong>Storage Location:</strong> Data is securely stored on AWS cloud servers.
                                    </li>
                                    <li><strong>Retention:</strong> Data is stored indefinitely unless the user requests
                                        its deletion. Backups and server logs may retain residual data for up to 30 days
                                        for operational purposes.
                                    </li>
                                </ul>

                                <h4 className="text-orange">4. Data Sharing</h4>
                                <ul>
                                    <li><strong>Analytics:</strong> Anonymized behavioral data is processed using
                                        third-party analytics tools, such as Google Analytics and Mixpanel.
                                    </li>
                                    <li><strong>Legal Compliance:</strong> We may disclose data if required by law or to
                                        protect our rights.
                                    </li>
                                </ul>

                                <h4 className="text-orange">5. Data Security</h4>
                                <ul>
                                    <li><strong>In Transit:</strong> Data is secured using HTTPS (Hypertext Transfer
                                        Protocol Secure).
                                    </li>
                                    <li><strong>At Rest:</strong> Data is encrypted using AES (Advanced Encryption
                                        Standard).
                                    </li>
                                    <li>While we strive to protect your data, no system is completely secure. Therefore,
                                        we cannot guarantee absolute security.
                                    </li>
                                </ul>

                                <h4 className="text-orange">6. Third-Party Resumes</h4>
                                <p>Users are solely responsible for ensuring they have the necessary consent to upload
                                    third-party resumes. Psygenie Softwares is not liable for unauthorized uploads or
                                    misuse of third-party data.</p>

                                <h4 className="text-orange">7. Predictions Disclaimer</h4>
                                <p>The predictions and recommendations provided are based solely on user-provided data.
                                    They are not guaranteed to be accurate or professional advice. Users should exercise
                                    their own judgment.</p>

                                <h4 className="text-orange">8. User Rights</h4>
                                <p>
                                    Users can request access to their personal data stored by Psygenie, in accordance
                                    with applicable data protection laws, after verifying their identity. This includes
                                    data directly provided by the user and data generated as part of the service usage,
                                    excluding proprietary or internal system data.
                                </p>

                                <h4 className="text-orange">9. Policy Updates</h4>
                                <p>We may update this Privacy Policy from time to time. Changes will be communicated
                                    via:</p>
                                <ul>
                                    <li>Notifications through the plugin</li>
                                    <li>Email communication to registered users</li>
                                </ul>

                                <h4 className="text-orange">10. Contact Us</h4>
                                <ul>
                                    <li><strong>Email:</strong> info@psygenie.ai</li>
                                    <li><strong>Mailing Address:</strong> Psygenie Softwares, H24 Forest County,
                                        Kharadi, Pune - 411014
                                    </li>
                                </ul>

                                <h4 className="text-orange">11. Children’s Privacy</h4>
                                <p>Psygenie is not intended for individuals under the age of 18. We do not knowingly
                                    collect data from children. If you believe a child has provided us with personal
                                    data, please contact us to delete the information.</p>

                                <h4 className="text-orange">12. Compliance</h4>
                                <ul>
                                    <li>GDPR (General Data Protection Regulation)</li>
                                    <li>CCPA (California Consumer Privacy Act)</li>
                                </ul>

                                <h4 className="text-orange">13. Limitation of Liability</h4>
                                <p>Psygenie Softwares is not liable for any direct, indirect, incidental, consequential,
                                    or special damages arising from the use of the plugin, including actions based on
                                    predictions.</p>

                                <h4 className="text-orange">14. Disclaimer of Warranties</h4>
                                <p>Psygenie Softwares provides the plugin "as-is" without warranties of any kind.</p>

                                <h4 className="text-orange">15. Indemnity Clause</h4>
                                <p>Users agree to indemnify Psygenie Softwares against claims arising from data uploaded
                                    or actions based on predictions.</p>

                                <h4 className="text-orange">16. Future Features</h4>
                                <p>New features requiring additional data will notify users and seek explicit consent
                                    before activation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Privacy;