import React, { useEffect, useState } from "react";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import styles from "../../../../css/PlansList.module.css";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import * as PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function PlanCard(props) {
  return (
    <div className={`col-md-3 ${styles.plan_card} ${props.plan.code} m-1`}>
      <h3>{props.plan.name}</h3>
      <p>{props.plan.description}</p>
      <p>
        <strong>Price:</strong> {props.plan.currency} {props.plan.price} (
        {props.plan.period})
      </p>
      <button className={styles.subscribe_btn} onClick={props.onClick}>
        Subscribe
      </button>
    </div>
  );
}

PlanCard.propTypes = {
  plan: PropTypes.any,
  onClick: PropTypes.func,
};

const PlansList = () => {
  const [plans, setPlans] = useState([]);
  const [user, setUser] = useState(AuthenticationService.getLoggedInUser);
  const [paid] = useState(AuthenticationService.isPaidUser);
  const navigate = useNavigate();

  /*
    useEffect(() => {
        axios
            .get(`/payments/subscription/plans/visible`)
            .then((response) => {
                setPlans(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
*/

  const handleSubscribe = (planCode) => {
    axios
      .post(`/payments/subscription/new`, {
        plancode: planCode,
        username: user,
      })
      .then((response) => {
        navigate("/plans/checkout", {
          state: response.data.data,
        });
      })
      .catch((error) => {
        console.error("Error subscribing to plan: ", error);
      });
  };

  return (
    <>
    {!paid && (
    <div className={styles.plans_list_container}>
      <div className="container text-center">
        <div className="column">
          <div className="pricing-card basic">
            <div className="pricing-header">
              <span className="plan-title">
                <i className="fa fa-bicycle" /> Intro Plan
              </span>
              <div className="price-circle">
                <span className="price-title">
                  <small>$</small>
                  <span>0</span>
                </span>
                <span className="info">/ Month</span>
              </div>
            </div>
            <div className="badge-box">
              <span>Free Plan</span>
            </div>
              <ul className="">
                  <li>
                      <i className="fa fa-check text-primary fw-bold"></i> Personal Profile Insights
                  </li>
                  <li>
                      <i className="fa fa-check text-primary"></i> 5 Persona Analysis Credits
                  </li>
                  <li>
                      <i className="fa fa-check text-primary"></i> Bot Access (Limited)
                  </li>
                  <li>
                      <i className="fa fa-check text-primary"></i> Note Analysis (Limited)
                  </li>
                  <li>
                      <i className="fa fa-check text-primary"></i> Basic Behavioral Insights
                  </li>
                  <li>
                      <i className="fa fa-check text-primary"></i> Basic Behavioral Insights
                  </li>
              
              
              </ul>
              {/*<div className="buy-button-box">
              <a
                href={AuthenticationService.getSlackOpenURL()}
                className="buy-now"
              >
                Add to Chrome
              </a>
            </div>*/}
          </div>
        </div>
        <div className="column">
            <div className="pricing-card echo">
                <div className="pricing-header">
              <span className="plan-title">
                <i className="fa fa-motorcycle"/> Flexible Plan
              </span>
                    <div className="price-circle">
                <span className="price-title">
                  $<span>15</span>
                </span>
                        <span className="info">/ Month</span>
                    </div>
                </div>
                {/*<div className="badge-box">
                    <span>Full Access</span>
                </div>*/}
                <div className="buy-button-box mb-3">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubscribe("PGSTD_MON");
                        }}
                        className="buy-now"
                    >Subscribe</a>
                </div>
                <ul>
                    <li>
                        <i className="fa fa-check text-warning fw-bold"></i> In-Depth Profile Analysis
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Premium Features Access
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> 20 Persona Analysis Credits
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Enhanced Bot Access
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Advanced Behavioral Insights
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Extensive Notes Analysis
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> 80000 Tokens per day
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Regular Persona Analysis
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Regular Persona Analysis
                    </li>
                    <li>
                        <i className="fa fa-check text-warning"></i> Priority Support
                    </li>
                </ul>
                {/*<div className="buy-button-box">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubscribe("PGSTD_MON");
                        }}
                        className="buy-now"
                    >
                        SUBSCRIBE NOW
                    </a>
                </div>*/}
            </div>
        </div>
          <div className="column">
              <div className="pricing-card pro">
                  <div className="popular">POPULAR</div>
                  <div className="pricing-header">
              <span className="plan-title">
                <i className="fa fa-car"/> Ultimate Yearly
              </span>
                      <div className="price-circle">
                <span className="price-title">
                    $<span>180</span>
                </span>
                          <span className="info">/ Year</span>
                      </div>
                  </div>
                  {/*<div className="badge-box">
              <span>Free 30 Days</span>
            </div>*/}
                  <div className="buy-button-box mb-3">
                      <a
                          href="#"
                          onClick={(e) => {
                              e.preventDefault();
                              handleSubscribe("PGSTD_YRS");
                          }}
                          className="buy-now"
                      >Subscribe</a>
                  </div>
                  <ul>
                      <li>
                          <i className="fa fa-check text-success fw-bold"></i> In-Depth Profile Analysis
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Premium Features Access
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> 20 Persona Analysis Credits
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Enhanced Bot Access
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Advanced Behavioral Insights
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Extensive Notes Analysis
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> 80000 Tokens per day
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Regular Persona Analysis
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Regular Persona Analysis
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Priority Support
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Extra Tokens per Day
                      </li>
                      <li>
                          <i className="fa fa-check text-success"></i> Extra Persona Credits
                      </li>
                  </ul>
                  {/*<div className="buy-button-box">
                      <a
                          href="#"
                          onClick={(e) => {
                              e.preventDefault();
                              handleSubscribe("PGSTD_YRS");
                          }}
                          className="buy-now"
                      >
                          SUBSCRIBE NOW
                      </a>
                  </div>*/}
              </div>
          </div>
          <div className="column">
              <div className="pricing-card business">
                  <div className="pricing-header">
              <span className="plan-title">
                <i className="fa fa-plane"/> Corporate Access
              </span>
                      <div className="price-circle">
                <span className="price-title">
                  <span>
                    <i className="fa fa-envelope"/>
                  </span>
                </span>
                          <span className="info"></span>
                      </div>
                  </div>
                  <div className="badge-box">
                      <span>Contact Us</span>
                  </div>
                  <ul>
                      <li>Unlock Corporate Access with tailored solutions, advanced analytics, and scalable features for your team. Contact us to learn more and get started today.
                      </li>
                      <li>
                          <i className="fa fa-contact-card"></i> <code>info@psygenie.ai</code>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    )}
  </>
  );
};

export default PlansList;
