import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AuthenticationService from "../../api/authentication/AuthenticationService";
import { Navigate } from "react-router-dom";



const ProtectedRoutesGuest = () => {
  const [isUserLoggedIn] = useState(AuthenticationService.isUserLoggedIn());
  if (isUserLoggedIn) {
    return <Navigate to="/slack/app/psygenie/success" />;
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoutesGuest;
