.dropdown {
  position: absolute;
  border-radius: 15px;
}

.dropdownMenu {
  position: absolute;
  background-color: white;
  border: 1px solid #666;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  max-width: 80vw;
  overflow: hidden; /* This prevents content from spilling outside */
  text-overflow: ellipsis;
  z-index: 950;
}

.dropdownMenu-right {
  right: 0;
}

.dropdownMenu-left {
  left: 0;
}
