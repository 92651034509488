import React from 'react';
import {Container, Row, Col, Navbar} from 'react-bootstrap';
import logo from "../../../../img/logo.svg";

const Footer = () => {
    return (
        <section className="text-black mt-5" id="Footer">
            <div className="container pg_footer">
                <Row>
                    <Col md={5}>
                        <Navbar variant="light" expand="lg">
                            <Navbar.Brand className="text-wrap">
                                <img src={logo} alt="PsyGenie" width="40px"/>
                                <span>&nbsp;PsyGenie</span>
                                <span className="fs-6 text-muted"> Copyright &copy; 2024. All Rights Reserved.</span>
                            </Navbar.Brand>
                        </Navbar>
                    </Col>
                    <Col md={4}>
                        <Navbar variant="light" expand="lg">
                            <Navbar.Brand>
                                <span className="fs-6">
                                    <a href="/contactus" className="ms-5 text-muted">Contact Us</a>
                                    <a href="/privacy" className="ms-5 text-muted">Privacy</a>
                                     <a href="/terms" className="ms-5 text-muted">Terms</a>
                                    <a href="/aboutus" className="ms-5 text-muted">About Us</a>
                                </span>
                            </Navbar.Brand>
                        </Navbar>
                    </Col>
                    <Col md={3} className="text-md-right">
                        <Navbar variant="light" expand="lg">
                            <Navbar.Brand>
                                <div className="float-end">
                                {/*<span className="fs-6 text-muted ms-5">Social</span>*/}
                                <a href="https://www.facebook.com" className="ms-5"><i
                                        className="fab fa-facebook-f text-orange"></i></a>
                                <a href="https://www.twitter.com" className="ms-5"><i
                                    className="fab fa-twitter text-orange"></i></a>
                                <a href="https://www.instagram.com" className="ms-5"><i
                                    className="fab fa-instagram text-orange"></i></a>
                                </div>
                            </Navbar.Brand>
                        </Navbar>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Footer;