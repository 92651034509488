.landing_navbar {
  width: 40%;
  padding: 5px;
}

.navbar {
  margin-right: 10px;
  padding: 5px;
}

.nav_links {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0px;
}

.nav_link {
  color: #5f5f79;
  font-size: 18px;
  text-decoration: none;
}

.nav_button_login {
  color: white;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid;
  padding: 5px 25px;
  background: orangered;
}

.nav_button_login:hover {
  background: darkorange;
}

@media all and (min-width: 900px) {
  .popup_menu {
    display: none;
  }
  .menu {
    display: none;
  }

  .nav_link {
    font-size: 14px;
  }
}

@media all and (max-width: 900px) {
  .menu {
    height: 25px;
    float: right;
    padding-right: 20px;
    font-size: 12px;
    filter: invert(85%) sepia(3%) saturate(5839%) hue-rotate(183deg)
      brightness(74%) contrast(95%);
    color: rgb(135, 158, 187);
    cursor: pointer;
    font-weight: 200;
    position: absolute;
    top: 3%;
    right: 10px;
  }
  .nav_links_popup {
    list-style: none;
    z-index: 2;
    padding: 5px;
    text-shadow: 0.1px 0px 0px rgb(241, 215, 167);
  }

  .popup_menu {
    z-index: 99 !important;
    position: absolute;
    padding: 5px;
    background: white;
    top: 6%;
    right: 5%;
    width: auto;
    height: auto;
    box-shadow: -2px 0px 33px 0px rgba(0, 0, 0, 0.15);
  }

  .nav_links {
    display: none;
  }
  .nav_link {
    font-size: 12px;
    line-height: 30px;
  }
}
@media all and (max-width: 280px) {
  .menu {
    position: absolute;
    top: 2%;
  }
}
