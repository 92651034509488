import React from "react";
import styles from "./../../../../css/PopUp.module.css";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// Icon mapping based on type using Font Awesome
const iconMap = {
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  info: faInfoCircle,
  success: faCheckCircle,
};

const Popup = ({
  isOpen,
  title,
  message,
  type = "info",
  onClose,
  cancelButton,
  closeButton,
  buttons = [],
  children,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <div className={styles.header}>
          <div className={styles.titlebar}>
            <span className={styles.icon}>
              <FontAwesomeIcon icon={iconMap[type]} />
            </span>
            <h2 className={styles.title}>{title}</h2>
          </div>
          {closeButton && (
            <button className={styles.close} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        <div className={styles.message}>{message}</div>

        {/* Render children if passed */}
        {children && <div className={styles.childrenContainer}>{children}</div>}

        <div className={styles.footer}>
          {cancelButton && (
            <button className={styles.cancel} onClick={onClose}>
              Cancel
            </button>
          )}
          {buttons.map((btn, index) => (
            <button
              key={index}
              className={styles.button}
              style={{ backgroundColor: btn.color }}
              onClick={btn.handler}
            >
              {btn.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["warning", "error", "info", "success"]),
  onClose: PropTypes.func.isRequired,
  cancelButton: PropTypes.bool,
  closeButton: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
      color: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
};

export default Popup;

// Default popups
export const ConfirmationPopup = (props) => (
  <Popup {...props} type="warning" title="Confirm Action" />
);

export const InfoPopup = (props) => (
  <Popup {...props} type="info" title="Information" />
);

export const WarningPopup = (props) => (
  <Popup {...props} type="warning" title="Warning" />
);

export const ErrorPopup = (props) => (
  <Popup {...props} type="error" title="Error" />
);
