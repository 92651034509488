import HeroSectionOthers from "./common/HeroSectionOthers";
import React from "react";
import Footer from "./landing/Footer";

const description = "These Terms and Conditions explain how Psygenie Softwares grants access and defines the permissible use of the Psygenie Chrome plugin. By using our plugin, you agree to abide by these Terms.";

const Terms = () => {
    return (
        <div className="main-content-div">
            <HeroSectionOthers title="Terms and Conditions for PsyGenie" description={description}/>
            <section id="Terms" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 align-self-center">
                            <div className="tab-content translations-content-item en visible" id="en">
                                <p><strong>Effective Date:</strong> January 17, 2025</p>
                                <h2 className="text-orange">Terms and Conditions for Psygenie Softwares</h2>
                                <p>Welcome to Psygenie! These Terms and Conditions ("Terms") govern your access to and use of the Psygenie Chrome plugin ("Plugin"), owned and operated by Psygenie Softwares ("we," "our," or "us"). By installing or using the Plugin, you agree to these Terms. If you do not agree, please discontinue use immediately.</p>

                                <h4 className="text-orange">1. Definitions</h4>
                                <ul>
                                    <li><strong>Plugin:</strong> Refers to the Psygenie Chrome plugin designed to help users analyze resumes and day-to-day interactions for personalized insights.</li>
                                    <li><strong>User:</strong> Refers to any individual or entity using the Plugin.</li>
                                    <li><strong>Content:</strong> Includes predictions, recommendations, and analysis provided by the Plugin.</li>
                                </ul>

                                <h4 className="text-orange">2. Acceptable Use</h4>
                                <ul>
                                    <li>Use the Plugin only for lawful purposes.</li>
                                    <li>Refrain from:
                                        <ul>
                                            <li>Uploading data or resumes without the necessary consent.</li>
                                            <li>Misusing or exploiting predictions or recommendations for harmful activities.</li>
                                            <li>Sharing or transferring your account credentials with others.</li>
                                            <li>Accessing the Plugin using automated tools, scripts, or bots.</li>
                                        </ul>
                                    </li>
                                </ul>

                                <h4 className="text-orange">3. Grant of License</h4>
                                <p>Subject to your compliance with these Terms, Psygenie Softwares grants you a personal, non-exclusive, non-transferable, revocable right to access and use the Plugin through your registered account. These access rights do not transfer ownership or any intellectual property rights to you.</p>

                                <h4 className="text-orange">4. Intellectual Property</h4>
                                <ul>
                                    <li>All rights, titles, and interests in the Plugin and its Content, including but not limited to trademarks, logos, code, and design, are owned by Psygenie Softwares.</li>
                                    <li>You may not copy, reproduce, distribute, or create derivative works of the Plugin or its Content without prior written consent from Psygenie Softwares.</li>
                                    <li>All rights not expressly granted to the user under these Terms are reserved by Psygenie Softwares.</li>
                                </ul>

                                <h4 className="text-orange">5. Access and Account Requirements</h4>
                                <ul>
                                    <li>Access to the Plugin is granted only to users with an active and valid account.</li>
                                    <li>Your access may be suspended or terminated if:
                                        <ul>
                                            <li>Subscription payments are not received.</li>
                                            <li>You violate these Terms.</li>
                                        </ul>
                                    </li>
                                </ul>

                                <h4 className="text-orange">6. User Responsibilities</h4>
                                <ul>
                                    <li><strong>Data Accuracy:</strong> You are responsible for ensuring the accuracy and legality of any data or resumes you upload.</li>
                                    <li><strong>Third-Party Consent:</strong> If you upload third-party data (e.g., a colleague's or candidate's resume), you must obtain their explicit consent.</li>
                                    <li><strong>Compliance:</strong> You agree to comply with all applicable laws and regulations while using the Plugin.</li>
                                </ul>

                                <h4 className="text-orange">7. Predictions Disclaimer</h4>
                                <p>The predictions and recommendations provided are based solely on user-provided data and are for informational purposes only. Psygenie Softwares does not guarantee their accuracy or applicability.</p>
                                <p>Users do not own the outputs (e.g., predictions, analysis, recommendations) generated by the Plugin. These outputs cannot be redistributed or commercialized.</p>

                                <h4 className="text-orange">8. Disclaimer of Warranties</h4>
                                <ul>
                                    <li>The Plugin is provided "as-is" without warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>
                                    <li>Psygenie Softwares does not guarantee uninterrupted or error-free operation of the Plugin.</li>
                                </ul>

                                <h4 className="text-orange">9. Limitation of Liability</h4>
                                <ul>
                                    <li>Psygenie Softwares is not liable for any direct, indirect, incidental, consequential, or special damages arising from the use or inability to use the Plugin.</li>
                                    <li>This includes damages related to decisions made based on Plugin-generated predictions or any data uploaded by users.</li>
                                </ul>

                                <h4 className="text-orange">10. Indemnification</h4>
                                <p>Users agree to indemnify and hold Psygenie Softwares harmless from any claims, liabilities, damages, losses, or expenses arising out of their use of the Plugin, including but not limited to data uploaded or decisions made based on Plugin-generated predictions.</p>

                                <h4 className="text-orange">11. Contact Us</h4>
                                <ul>
                                    <li><strong>Email:</strong> info@psygenie.ai</li>
                                    <li><strong>Mailing Address:</strong> Psygenie Softwares, H24 Forest County, Kharadi, Pune - 411014</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Terms;