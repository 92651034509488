import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHash = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const target = document.getElementById(hash.substring(1)); // Remove '#' from hash
            if (target) {
                target.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }, [hash]);

    return null;
};

export default ScrollToHash;
