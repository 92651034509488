import React from "react";

import HeroSection from "./landing/HeroSection";
import FeaturesSection from "./landing/FeaturesSection";
import Footer from "./landing/Footer";
import PersonalInsights from "./landing/PeronalInsight";
import BotSection from "./landing/BotSection";
import BehaviorSection from "./landing/BehaviorSection";
import PlanSection from "./landing/PlanSection";
import IntegrationSection from "./landing/IntegrationSection";
import BlogsSection from "./landing/BlogsSection";
import PersonalIcebreakerSection from "./landing/PesonalIcebreakerSection";
import TestimonialsSection from "./landing/TestimonialsSection";


const Home = () => {
  return (
      <>
          <div>
              <HeroSection/>
              <TestimonialsSection/>
              <BehaviorSection/>
              <BotSection/>
              <PersonalIcebreakerSection/>
              {/*<PersonalInsights/>*/}
              {/*<TestimonialsSection/> */}
              {/*<ContactSection/>*/}
              <PlanSection />
              {/*<BlogsSection />*/}
              <IntegrationSection />
              {/*<FeaturesSection/>*/}
              <Footer/>
          </div>
      </>
  );
};

export default Home;
