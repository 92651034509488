import React from 'react';
import orangeBrain from "../../../../img/landing/orange-brain-holds-magnifying-glass.png"
import mainImage from "../../../../img/landing/Instant_insight.png"
const PersonalInsight = () => {
    return (
        <>
            <section id="Dashboard" className="personal-insight py-5 text-center">
                <div className="container">
                    <div className="animated-image">
                        <img src={orangeBrain} width={100} height={100}/>
                    </div>
                </div>
                <h2 className="text-center fs-1 fw-bold mt-5">Our Dashboards</h2><br/>
                <div className="personal-insight-container container mb-5">
                    <div className="text-center mb-5">
                        Explore personalized suggestions for more effective interactions, track your progress, and
                        receive tailored recommendations for continuous self-improvement. Navigate through intuitive
                        features designed to support your journey toward better communication and deeper connections.
                        Your growth starts here!
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <img src={mainImage} alt="" className="img-fluid border border border-warning rounded-4"/>
                        </div>
                        <div className="col-md-4 align-self-center mt-5">
                            <h2 className="fs-1 fw-bold">Better Understand the Individual</h2>
                            <p className="fs-4">"Better Understand the Individual: Explore Key Insights into
                                Personality, Behavior, and Emotions"—designed to help you gain a deeper understanding of
                                unique personal traits, moods, and behaviors for enhanced self-awareness and growth.</p>
                            <a href="/" className="btn btn-outline-warning rounded-4 btn-lg"
                               data-wow-delay=".6s">Know more</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PersonalInsight;