import React from 'react';
import reflectionImage from "../../../../img/landing/self-reflection.png";
import communicationImage from "../../../../img/landing/communication.png";
import personalityImage from "../../../../img/landing/personality.png";
import {Card, Col, Row} from "react-bootstrap";
const BlogsSection = () => {
    return (
        <>
            <section id="Blogs" className="blogs-section py-5">
                <div className="blog-section-container container">
                    <h2 className="text-center mb-2 fw-bold" id="Feature">Discover Our Authors' Posts on the
                        Newsfeed</h2>
                    <p className="text-center mb-5">Welcome to the PsyGenie Blog, your go-to resource for insights on psychology, personal development, and effective communication. Here, we share valuable articles, tips, and research findings to help you understand yourself and others better, enhancing your relationships and personal growth.</p>
                    <Row>
                        <Col md={4} className="mb-5">
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Img height={275} src={reflectionImage}></Card.Img>
                                    <Card.Text className="fs-5">The Power of Self-Reflection: Why It Matters for Personal Growth</Card.Text>
                                    <Card.Footer className="text-muted">Learn the importance of self-reflection and how it can drive your journey toward self-improvement.</Card.Footer>
                                    <Card.Link className="float-end" href="">Continue Reading</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-5">
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Img height={275} src={communicationImage}></Card.Img>
                                    <Card.Text className="fs-5">Effective Communication: Tips for Engaging Conversations</Card.Text>
                                    <Card.Footer className="text-muted">Gain practical strategies for improving your communication skills, fostering deeper connections with others.</Card.Footer>
                                    <Card.Link className="float-end" href="">Continue Reading</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-5">
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Img height={275} src={personalityImage}></Card.Img>
                                    <Card.Text className="fs-5">Understanding Your Personality: The Key to Better Relationships</Card.Text>
                                    <Card.Footer className="text-muted">Explore how knowing your personality type can transform your interactions and lead to healthier relationships.</Card.Footer>
                                    <Card.Link className="float-end" href="">Continue Reading</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default BlogsSection;