import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../../css/Checkbox.module.css";

const Checkbox = ({
  label,
  defaultChecked = false,
  onChange,
  color = "#fff",
  backgroundColor = "#FF5F00FF",
}) => {
  // State to manage checkbox status
  const [isChecked, setIsChecked] = useState(defaultChecked);

  // Handler for checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    // Call the onChange handler passed as prop
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <label className={styles.checkboxComponent} onClick={handleCheckboxChange}>
      {/* Custom Checkbox */}
      <div
        className={styles.checkbox}
        style={{
          borderColor: backgroundColor,
          backgroundColor: isChecked ? backgroundColor : "transparent",
        }}
      >
        {/* FontAwesome tick icon */}
        {isChecked && (
          <FontAwesomeIcon icon={faCheck} style={{ color: color }} />
        )}
      </div>
      {/* Checkbox label */}
      <span className={styles.labelText}>{label}</span>
    </label>
  );
};

export default Checkbox;
