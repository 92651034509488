import AuthenticationService from "../authentication/AuthenticationService";

const SlackRedirectService = async () => {
    try {
        if (AuthenticationService.isUserLoggedIn()) {
            window.location.href = "/slack/app/psygenie/allow";
        } else {
            localStorage.setItem("redirectURL", "slack/app/psygenie/allow");
            window.location.href = "/login"
        }
    } catch (error) {
        console.log('error signing in', error);
    }
};

export default SlackRedirectService;