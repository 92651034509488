import React from "react";
import loaderImg from "../../../../img/loader.gif";

const Loader = () => {
    return (
        <>
            <div className="text-center"><img src={loaderImg} alt="blueImg2"></img></div>
        </>
    );
};

export default Loader;
