const awsConfig = {
    Auth: {
        Cognito: {
            region: 'us-east-1',
            userPoolId: 'us-east-1_3xCRRi4sO',
            userPoolClientId: '7roauo735suad9ab66mluju6df',
            loginWith: {
                oauth: {
                    domain: 'psygenie.auth.us-east-1.amazoncognito.com',
                    scopes: ['email', 'openid', 'profile'],
                    providers: ["Google"],
                    redirectSignIn: [`${process.env.REACT_APP_AWS_CONFIG_REDIRECT}`],// Update this to your application's URL
                    redirectSignOut: [`${process.env.REACT_APP_AWS_CONFIG_REDIRECT}`],
                    responseType: 'code',
                    redirectUri: `${process.env.REACT_APP_AWS_CONFIG_REDIRECT}`
                },
                email: 'true',
                name: 'true'
            }
        }
    }
};


export default awsConfig;