import React, { useEffect } from 'react';
import pgAxios from "../customAxiosConfig/CustomAxiosConfig";
import Loader from "../../components/root/fragments/Loader/Loader";

const SlackAuthenticateService = (url, config) => {
    useEffect((url, config) => {
        const fetchAccessToken = async () => {
            const query = new URLSearchParams(window.location.search);
            console.log("Query ->" + JSON.stringify(query));
            const code = query.get('code');
            console.log("Code ->" + code);
            if (code) {
                try {
                    await pgAxios.post(`/slack/oauth/callback`, null, {
                        params: {
                            "code": code,
                        },
                    });
                    window.location.href = "/slack/app/psygenie/success";
                } catch (err) {
                    let error = "";
                    if (err.response) {
                        error += err.response;
                    }
                    return error;
                }
            }
        };
        fetchAccessToken();
    });
    return <div class="container mt-5" ><Loader /></div>;
};

export default SlackAuthenticateService;