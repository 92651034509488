import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import axios from "../../../../api/customAxiosConfig/CustomAxiosConfig";
import { Loader } from "@aws-amplify/ui-react";

const Plans = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const status = async () => {
      try {
        const user = AuthenticationService.getLoggedInUser();
        const response = await axios.post(`/payments/subscription/status`, {
          username: user,
        });

        const { data, success, error } = response.data;
        if (success) {
          if (data && data.status === "unavailable") {
            navigate("/plans/list");
          } else if (data && data.status === "created") {
            navigate("/plans/selected");
          } else if (data && data.status === "halted") {
            navigate("/plans/inactive");
          } else if (data && (data.status === "active" || data.status === "cancelled")) {
            navigate("/plans/active");
          } else {
            console.log("Unknown status: ", data?.status);
          }
        } else {
          console.log(`Error: ${error}`);
        }
      } catch (err) {
        console.log("Error occurred:", err);
      }
    };

    if (AuthenticationService.isUserLoggedIn()) {
      status();
    } else {
      navigate("/plans/view");
    }
  }, [navigate]);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Plans;
