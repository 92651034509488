import React from 'react';
import {Button, Container} from "react-bootstrap";
import slackIcon from "../../../../img/landing/slack-icon.png";
import teamsIcon from "../../../../img/landing/team_icon.png";
import whatsappIcon from "../../../../img/landing/whatsapp-icon.png"; // Replace with correct path to WhatsApp icon
import linkedinIcon from "../../../../img/landing/linkedin-icon.png"; // Replace with correct path to LinkedIn icon
const IntegrationSection = () => {
    return (
        <section className="py-5 mt-5 mb-5" id="Integration">
            <div className="integration_icon animated-image integration-1">
                <img src={slackIcon} alt={"Slack Icon"}/>
            </div>
            <div className="integration_icon animated-image integration-2">
                <img src={teamsIcon} alt={"Teams Icon"}/>
            </div>
            <div className="integration_icon animated-image integration-4">
                <img src={whatsappIcon} alt={"WhatsApp Icon"}/>
            </div>
            <div className="integration_icon animated-image integration-5">
                <img src={linkedinIcon} alt={"LinkedIn Icon"}/>
            </div>

            <Container className="mt-5">
                <h2 className="text-center mb-2 fw-bold mt-5" id="Feature">Integrate PsyGenie Across Your Favorite Communication Apps!</h2>
                <div className="text-center mb-5 mt-3 text-center w-50">
                    Our Chrome plugin integrates seamlessly with <strong>Slack</strong>, <strong>Teams</strong>, <strong>WhatsApp</strong>, and <strong>LinkedIn</strong>. Simply open the communication app in your browser, and PsyGenie's real-time bot will be ready to assist you. Experience enhanced psychological tactics and better human connections in every interaction!
                </div>
            </Container>
        </section>
    )
};

export default IntegrationSection;