import styles from "../../../../css/PlansList.module.css";
import React from "react";

const PlanSection = () => {
return (
    <section className="py-5 mb-5 plan_section" id="Plans">
        <div className={styles.plans_list_container}>
            <div className="container text-center text-white">
                <h2>Empower Your Journey with the Right Plan</h2>
                <div className="display-7 mt-2 mb-5">Choose the plan that best suits your journey with PsyGenie. Each option is
                    designed to provide tailored insights into personality, emotions, and behaviors, enhancing your
                    interactions and personal growth. Unlock real-time suggestions and personalized training that empower
                    you to connect more effectively with others and improve yourself continuously. Start your transformative
                    journey today!
                </div>
            </div>
            <div className="container text-center">
                <div className="column">
                    <div className="pricing-card basic">
                        <div className="pricing-header">
                            <span className="plan-title"><i className="fa fa-bicycle"/> Intro Plan</span>
                            <div className="price-circle">
                                <span className="price-title">
                                  <small>$</small><span>0</span>
                                </span>
                                <span className="info">/ Month</span>
                            </div>
                        </div>
                        <div className="badge-box">
                            <span>Free Plan</span>
                        </div>
                        <ul>
                            <li>
                                <i className="fa fa-check text-primary fw-bold"></i> Personal Profile Insights
                            </li>
                            <li>
                                <i className="fa fa-check text-primary"></i> 5 Persona Analysis Credits
                            </li>
                            <li>
                                <i className="fa fa-check text-primary"></i> Bot Access (Limited)
                            </li>
                            <li>
                                <i className="fa fa-check text-primary"></i> Note Analysis (Limited)
                            </li>
                            <li>
                                <i className="fa fa-check text-primary"></i> Basic Behavioral Insights
                            </li>
                            <li>
                                <i className="fa fa-check text-primary"></i> Basic Behavioral Insights
                            </li>
                        </ul>
                        {/*<div className="buy-button-box">
                            <a href="#" className="buy-now">START</a>
                        </div>*/}
                    </div>
                </div>
                <div className="column">
                    <div className="pricing-card echo">
                        <div className="pricing-header">
                            <span className="plan-title"><i className="fa fa-motorcycle"/> Flexible Plan</span>
                            <div className="price-circle">
                                <span className="price-title">
                                  $<span>15</span>
                                </span>
                                <span className="info">/ Month</span>
                            </div>
                        </div>
                        <div className="badge-box">
                            <span>Monthly Plan</span>
                        </div>
                        <ul>
                            <li>
                                <i className="fa fa-check text-warning fw-bold"></i> In-Depth Profile Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Premium Features Access
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> 20 Persona Analysis Credits
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Enhanced Bot Access
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Advanced Behavioral Insights
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Extensive Notes Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> 80000 Tokens per day
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Regular Persona Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Regular Persona Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-warning"></i> Priority Support
                            </li>
                        </ul>
                        {/*<div className="buy-button-box">
                            <a href="#" className="buy-now">SUBSCRIBE
                                NOW</a>
                        </div>*/}
                    </div>
                </div>
                <div className="column">
                    <div className="pricing-card pro">
                        <div className="popular">POPULAR</div>
                        <div className="pricing-header">
                            <span className="plan-title"><i className="fa fa-car"/> Ultimate Yearly</span>
                            <div className="price-circle">
                                <span className="price-title">
                                  $<span>180</span>
                                </span>
                                <span className="info">/ Year</span>
                            </div>
                        </div>
                        <div className="badge-box">
                            <span>Yearly Plan</span>
                        </div>
                        <ul>
                            <li>
                                <i className="fa fa-check text-success fw-bold"></i> In-Depth Profile Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Premium Features Access
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> 20 Persona Analysis Credits
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Enhanced Bot Access
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Advanced Behavioral Insights
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Extensive Notes Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> 80000 Tokens per day
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Regular Persona Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Regular Persona Analysis
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Priority Support
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Extra Tokens per Day
                            </li>
                            <li>
                                <i className="fa fa-check text-success"></i> Extra Persona Credits
                            </li>
                        </ul>
                        {/*<div className="buy-button-box">
                            <a href="#" className="buy-now">SUBSCRIBE
                                NOW</a>
                        </div>*/}
                    </div>
                </div>
                <div className="column">
                    <div className="pricing-card business">
                        <div className="pricing-header">
                            <span className="plan-title"><i className="fa fa-plane"/> Corporate Access</span>
                            <div className="price-circle">
                                <span className="price-title">
                                  <span><i className="fa fa-envelope"/></span>
                                </span>
                                <span className="info"></span>
                            </div>
                        </div>
                        <div className="badge-box">
                            <span>Business Plan</span>
                        </div>
                        <ul>
                            <li>
                                Unlock Corporate Access with tailored solutions, advanced analytics, and scalable features for your team. Contact us to learn more and get started today.
                            </li>
                            <li>
                                <i className="fa fa-contact-card"></i> info@psygenie.ai
                            </li>
                            {/*<li>
                                <i className="fa fa-phone"></i> +91 9967195335
                            </li>*/}
                            {/*<li>
                                Thank you
                            </li>*/}
                        </ul>
                        {/*<div className="buy-button-box">
                            <a href="#" className="buy-now">CONTACT US</a>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    </section>
)
}

export default PlanSection;