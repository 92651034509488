import React from "react";
import { FcApproval } from "react-icons/fc"; // Add a disapprove icon
import style from "../../../../css/Settings.module.css"; // Adjust the path if necessary

const Settings = () => {
  return (
    <div className="main-content-div">
      <div className={style.settingsContainer}>
        <div className={style.connectors}>
          <div className="row">
            <div className="col">
              <div className={style.connectorCard}>
                <img
                  className={style.connectorIcon}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaPlbD42KHzVMn8SG2t54umtxYfOz2Mcwm8w&s"
                  alt="Slack"
                />
                <h3 className={style.connectorTitle}>Slack (Beta)</h3>
                <p className={style.connectorDescription}>
                  Manage your Slack integration settings.
                </p>
                <button className={style.connectedButton}>
                  <FcApproval /> Connected
                </button>
              </div>
            </div>
            <div className="col">
              <div className={style.connectorCard}>
                <img
                  className={style.connectorIcon}
                  src="https://framerusercontent.com/images/3ZqCimiZplranOdfENjjUaHiw.png"
                  alt="Gmail"
                />
                <h3 className={style.connectorTitle}>Gmail (Beta)</h3>
                <p className={style.connectorDescription}>
                  Manage your Gmail integration settings.
                </p>
                <button className={style.connectedButton}>
                  <FcApproval /> Connected
                </button>
              </div>
            </div>
            <div className="col">
              <div className={style.connectorCard}>
                <img
                  className={style.connectorIcon}
                  src="https://sujanbyanjankar.com.np/wp-content/uploads/2021/05/ms-teams.png"
                  alt="Team"
                />
                <h3 className={style.connectorTitle}>
                  Team Connect (Coming Soon)
                </h3>
                <p className={style.connectorDescription}>
                  Manage your Gmail integration settings.
                </p>
                <button className={style.connectButton}>Connect</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
