import React, {useState, useEffect, useRef} from 'react';
import "../../../../css/Landing.module.css";

const HeroSectionOthers = ({ title, description }) => {
    return (
        <>
            <section id="HeroOthers" className="heroOthers text-white py-5 section text-center bg-orange">
                <div className="container mt-5">
                    <div className="row align-items-center position-relative mt-5">
                        <div className="col-lg-12 mt-5 mb-5">
                            <h1 className="display-6 mb-5">{title}</h1>
                            <p className="lead">{description}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroSectionOthers;