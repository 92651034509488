import React, { useEffect, useRef } from "react";
import styles from "../../../../css/Dropdown.module.css";

const Dropdown = ({ children, isOpen, setIsOpen, width = "auto" }) => {
  const dropdownRef = useRef(null);
  const dropdownViewRef = useRef(null);
  const [leftPos, setLeft] = React.useState(0);
  const [topPos, setTop] = React.useState(0);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  useEffect(() => {
    if (dropdownViewRef.current) {
      const { bottom, right } = dropdownViewRef.current.getBoundingClientRect();
      const totalWidth = window.innerWidth;
      const totalHeight = window.innerHeight;

      if (right + 2 > totalWidth) {
        // 2px for margin
        setLeft(totalWidth - (right + 2));
      }

      if (bottom + 2 > totalHeight) {
        // 2px for margin
        setTop(totalHeight - (bottom + 2));
      }
    }
  }, [setIsOpen]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      {isOpen && (
        <div
          ref={dropdownViewRef}
          className={styles.dropdownMenu}
          style={{
            top: topPos,
            left: leftPos,
            position: "absolute", // Position absolute to the nearest positioned ancestor
            minWidth: width,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
