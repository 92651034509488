import React from "react";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";

const Logout = () => {
    AuthenticationService.logout();
    return (
        <></>
    );
};

export default Logout;