import React, { useState } from "react";
import { Button, Navbar } from "react-bootstrap";
import Footer from "../../fragments/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import LoginService from "../../../../api/login/LoginService";
import styles from "../../../../css/Forms.module.css";
import style from "../../../../css/Footer.module.css";
import style_background from "../../../../css/Background.module.css";
import style_login from "../../../../css/Login.module.css";
import AuthenticateUserDataService from "../../../../api/authentication/AuthenticateUserDataService";
import LoadingDotsDark from "./animation/LoadingDotsDark";
import GoogleLoginButton from "../../../../api/login/GoogleLoginButton";
import loginImg from "../../../../img/login.png";
import pglogo from "../../../../img/pglogo.svg";
import {signOut} from "aws-amplify/auth";

const Login = () => {
  // Redirect to AWS authentication screen if aws cognito is enabled
  const awsCognitoEnabled = useState(AuthenticationService.awsCognitoEnabled);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [loginState, setLoginState] = useState({
    hasLoginFailed: false,
    showSuccessMessage: false,
  });

  if (AuthenticationService.awsCognitoEnabled) {
    signOut().then(r =>AuthenticationService.signIn());
    return <></>;
  }

  const validate = () => {
    const errors = {};

    if (!credentials.username) {
      errors.username = "Email required";
    }

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(credentials.username)
    ) {
      errors.username = "Invalid email address";
    }

    if (!credentials.password) {
      errors.password = "A password is required";
    }

    return errors;
  };

  const loginClicked = async (event) => {
    event.preventDefault();
    let errors = validate(credentials);
    setErrors(errors);
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const res = await AuthenticateUserDataService(
        credentials.username,
        credentials.password
      );
      console.log(res.data);

      if (res.status !== 200) {
        setLoading(false);
        setLoginState((prevState) => ({ ...prevState, hasLoginFailed: true }));
        setLoginState((prevState) => ({
          ...prevState,
          showSuccessMessage: false,
        }));
      } else {
        let jwtToken = res.data.jwtToken;
        const token = `Bearer ${jwtToken}`;
        AuthenticationService.setUpToken(token);
        const response = await LoginService(credentials.username, jwtToken);
        console.log(response);
        if (response.status !== 200) {
          setLoading(false);
          setLoginState((prevState) => ({
            ...prevState,
            hasLoginFailed: true,
          }));
          setLoginState((prevState) => ({
            ...prevState,
            showSuccessMessage: false,
          }));
        } else if (response.data === "USER") {
          //@Todo: Send correct parameters for registerSuccessfulLoginUser
          AuthenticationService.registerSuccessfulLoginUser(
            credentials.username
          );
          navigate("/profile", { replace: true });
          window.location.reload();
        }
      }
    }
  };

  return (
          <div className={style_background.login_page}>
            <main>
              <div className={style_login.form_left}>
                <Navbar className={style_login.logo_nbar} variant="light" expand="lg">
                  <img src={pglogo} alt="PsyGenie" width="22px"/>&nbsp;<Navbar.Brand href="/">PsyGenie</Navbar.Brand>
                </Navbar>
                <form className={styles.form_style}>
                  <div className={style_login.static_header_content}>
                    <div className={style_login.static_header}>Welcome!</div>
                    <div>Login to your PsyGenie account.</div>
                  </div>
                  <div className={styles.login}>
                    {loginState.hasLoginFailed && (
                        <div className={styles.midErrors}> Invalid credentials</div>
                    )}
                    {loginState.showSuccessMessage && (
                        <div className={styles.midErrors}>Login successful</div>
                    )}
                  </div>

                  <div className={styles.form_field}>
                    <input
                        id="username"
                        type="text"
                        name="username"
                        onChange={(e) =>
                            setCredentials({...credentials, username: e.target.value})
                        }
                        required
                    />
                    <label htmlFor="username" className={styles.label_name}>
                      {Object.keys(errors).length === 0 && (
                          <span className={style_login.text}>Email</span>
                      )}
                      {errors.username && (
                          <small className={styles.errors}>{errors.username}</small>
                      )}
                    </label>
                  </div>
                  <div className={styles.form_field}>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={(e) =>
                            setCredentials({...credentials, password: e.target.value})
                        }
                        required
                    />
                    <label htmlFor="password" className={styles.label_name}>
                      {Object.keys(errors).length === 0 && (
                          <span className={style_login.text}>Password</span>
                      )}
                      {errors.password && (
                          <small className={styles.errors}>Password required</small>
                      )}
                    </label>
                  </div>
                  <p>
                    <Link
                        to="/change-password"
                        className={[
                          style_login.text,
                          style_login.forgot_password_text,
                        ].join(" ")}
                    >
                      Forgot your password?
                    </Link>
                  </p>
                  {loading && <LoadingDotsDark className={styles.dots}/>}
                  {!loading && (
                      <Button variant="success" onClick={loginClicked}>
                        Login
                      </Button>
                  )}
                  <GoogleLoginButton/>
                </form>

                <div className={style_login.signup_link}>
                  Don't have an account?&nbsp;
                  <Link className={style_login.signup_link_text} to="/signup">
                    Sign up
                  </Link>
                </div>
              </div>
              <div className={style_login.content_right}>
                <img src={loginImg} alt="blueImg3"></img>
              </div>
            </main>
            <Footer class={style.footer_cover}/>
          </div>
      );
};

export default Login;
