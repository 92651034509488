export const hideHeader = (currentLocation) => {
    return currentLocation.pathname === "/login" ||
        currentLocation.pathname === "/change-password" ||
        currentLocation.pathname === "/signup" ||
        currentLocation.pathname === "/slack/oauth/callback" ||
        currentLocation.pathname === "/slack/app/psygenie/allow" ||
        currentLocation.pathname === "/slack/authenticate" ||
        currentLocation.pathname === "/plans" ||
        currentLocation.pathname === "/plans/list" ||
        currentLocation.pathname === "/plans/view" ||
        currentLocation.pathname === "/plans/checkout" ||
        currentLocation.pathname === "/plans/selected" ||
        currentLocation.pathname === "/plans/active"
};

export const hideSidebar = (currentLocation) => {
    return currentLocation.pathname === "/slack/app/psygenie/success" ||
        currentLocation.pathname === "/slack/oauth/callback" ||
        currentLocation.pathname === "/slack/app/psygenie/allow" ||
        currentLocation.pathname === "/slack/authenticate"||
        currentLocation.pathname === "/plans" ||
        currentLocation.pathname === "/plans/list" ||
        currentLocation.pathname === "/plans/view" ||
        currentLocation.pathname === "/plans/checkout" ||
        currentLocation.pathname === "/plans/failed" ||
        currentLocation.pathname === "/plans/success" ||
        currentLocation.pathname === "/plans/selected" ||
        currentLocation.pathname === "/plans/active";
};

