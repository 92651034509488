import React from 'react';
import psygenieLogo from "../../../../img/logo.svg"
import style from "../../../../css/Common.module.css";
import AuthenticationService from "../../../../api/authentication/AuthenticationService";

const SlackAppPsygenieAllow = () => {
    return (
        <div className={style.integrationWindow}>
            <div className="container full-height d-flex justify-content-center align-items-center">
                <div className="">
                    <div class="text-center">
                        <img src={psygenieLogo} width="60px" alt="PsyGenie Slack Connection Success"/><span
                        class="fs-2"> PsyGenie</span>
                    </div>
                    <div className={style.integrationWindowContent}>
                        <div className="fs-3 text-center">Grant Permission</div>
                        <div className="fs-6 mt-2"><i class="fa-brands fa-slack"></i> <b>Slack</b> is requesting
                            permission
                            to use<b> PsyGenie</b> Connect to access your <b> PsyGenie</b> account.
                        </div>
                        <div className="fs-6 mt-2">If you authorize Slack, you will give it permission to:</div>
                        <div class="mt-2">
                            <div><i className="m-1">-</i> Access your name and email address.</div>
                            <div><i className="m-1">-</i> Access your Users, messages, and
                                workspaces.
                            </div>
                            <div><i className="m-1">-</i> Display your personality data on PsyGenie.</div>
                        </div>
                        <div className="fs-4 text-center mt-3">
                            <a className="btn btn-secondary" href={"/profile"}>Deny</a>
                            <a className="btn btn-primary m-2" href={AuthenticationService.getSlackAuthorizeURL()}>Allow</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SlackAppPsygenieAllow;