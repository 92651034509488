import React from 'react';
import mainImage from "../../../../img/landing/personal_personality.png";
import bot1 from "../../../../img/landing/bot1.png";
import bot2 from "../../../../img/landing/bot2.png";
import behImage from "../../../../img/landing/behImage.png";

const BotSection = () => {
    return (
        <>
            <section id="contact" className="instant-insight py-5 mt-5">
                <div className="instant-insight-container container mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={bot2} width="90%" alt="" className="img-fluid border border border-warning rounded-4"/>
                        </div>
                        <div className="col-md-3">
                            <img src={bot1} width="90%" alt="" className="img-fluid border border border-warning rounded-4"/>
                        </div>
                        <div className="col-md-6">
                            <h2 className="fs-1 fw-bold">PsyGenie bot - For real time communication</h2>
                            <p className="fs-3">"PsyGenie Bot seamlessly integrates with your communication apps to
                                provide psychological tactics for navigating complex workplace situations. Resolve
                                conflicts, give constructive feedback, and motivate your team with actionable insights.
                                Whether it’s a tough negotiation, a challenging appraisal, or team collaboration,
                                PsyGenie equips you with the tools to handle it all with confidence."</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BotSection;