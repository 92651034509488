.dropdownItem {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 15px;
  margin: 2px;
}

.dropdownItem:hover {
  background-color: #ff5f00ff;
  color: white;
}

.image {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 5px; /* Space between image and label */
}

.label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* To handle long labels */
}
