import React, { useRef, useState } from "react";
import Dropdown from "./Dropdown";

const DropdownWrapper = ({ children, dropdownItems }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState("auto");
  const wrapperRef = useRef(null);

  const handleClick = (e) => {
    const { width } = wrapperRef.current.getBoundingClientRect();
    setDropdownWidth(width);
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false); // Close dropdown
  };

  return (
    <div ref={wrapperRef}>
      <span style={{ display: "contents" }} onClick={handleClick}>
        {children}
      </span>
      <Dropdown
        isOpen={isDropdownOpen}
        setIsOpen={closeDropdown}
        width={dropdownWidth}
      >
        {dropdownItems}
      </Dropdown>
    </div>
  );
};

export default DropdownWrapper;
