import React, {useState, useEffect, useRef} from 'react';
import "../../../../css/Landing.module.css";
import logo from "../../../../img/logo.svg";
import heroPsychology from "../../../../img/hero_psychology.png";


const HeroSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const homeRef = useRef(null);

    const scrollToSection = (section) => {
        const element = document.querySelector(section)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <section id="Hero" ref={homeRef} className="hero text-white py-5 section pt-5">
                <div className="container hero_container mt-4">
                    <div className="row align-items-center position-relative">
                        <div className="col-lg-7">
                            <img className="hero-image" src={heroPsychology} width="85%"/>
                        </div>
                        <div className="col-lg-5 p-5">
                            <h1 className="text-center hero_tagline mb-3">
                                Enhance your <strong>HUMAN TOUCH</strong> and equip yourself with effective <strong>PSYCHOLOGICAL TACTICS</strong> with
                                <strong> PsyGenie </strong>
                                Your personal assistant to build stronger relationships.
                            </h1>
                            
                            <iframe
                                width="100%"
                                height="300px"
                                src="https://www.youtube.com/embed/PJc_iEkcnFo?si=r1eRhTDScnqi6j2T"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="border border-2 border-light hero-video rounded-4"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroSection;